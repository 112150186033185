<template>
   <div class="update-avatar">
    <img :src="img" ref="img"/>
    <div class="toolbar">
      <span @click="cancel">cancel</span>
      <span @click="confirm">confirm</span>
    </div>
  </div>

</template>

<script>
 import {fetch,post} from '@/util/api'
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
 import axios from 'axios'
export default {
  props: {
    img: {
      type: [String, Object],
      retuired: true
    }
  },
  data(){
    return{
    cropper:null,
   token:this.$store.getters.token,
  }
  },
  methods:{
    confirm:function(){
      // console.log(this.cropper.getData())
      this.cropper.getCroppedCanvas().toBlob(blob => {
        console.log(blob)

        var image = new FormData()
             //api/User/UploadImg
              
             image.append('image', blob,"file_" + Date.parse(new Date()) + ".png" );
             image.append('type', 3);
                image.append('check', 1);
             image.append("token",this.token);
             var url=process.env.VUE_APP_BASE_API+"/api/User/UploadImg";
             console.log("url:",url);
             this.$toast.loading({
            duration:0,
            message: 'uploading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
             axios.post(url,image,{
            headers: {
              "Content-Type": "multipart/form-data",
              "X-Mx-ReqToken":this.token
            }
            }).then(res=>{
              
                 console.log("res",res);
                 if(res.data.code==1){
                   //更新 用户的头像
                   post("/api/user/updateAvar",{url:res.data.url}).then(tres=>{
                     if(tres.code==1){
                       this.$toast("update success");
                       this.$emit("success");
                     }else{
                       this.$toast(tres.code_dec);
                     }
                     this.$toast.clear();
                   })
                 }else{
                   this.$toast(res.code_dec);
                 }
                
            }).catch(e=>{ 
                console.log("上传错误",e);
            })
      })
    },
    cancel:function(){
      console.log("cancel...");
      this.$emit("cancel");
    }
  },
  mounted(){
    const image = this.$refs.img
   
 this.cropper = new Cropper(image, {
          viewMode: 1, // 只能在裁剪的图片范围内移动
          dragMode: 'move', // 画布和图片都可以移动
          aspectRatio: 1, // 裁剪区默认正方形
          autoCropArea: 1, // 自动调整裁剪图片
          cropBoxMovable: false, // 禁止裁剪区移动
          cropBoxResizable: false, // 禁止裁剪区缩放
          background: false // 关闭默认背景
        })
console.log(this.cropper) 
  }
}
</script>

<style scoped lang='less'>
.update-avatar>img{
  width:100%;
}
.update-avatar {
  background: #000;
  width: 100%;
  height: 100%;
  .toolbar {
    position: fixed;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 28px;
    color: #fff;
    padding: 0 15px;
    box-sizing: border-box;
  }
}

</style>

