<template>
    <div class="my">
          <div class="userInfo">
              <div class="userInfo1">
                  <div>
                      <img :src="userInfo.header"/>
                      ID:{{userInfo.idcode}}
                  </div>
                  <a class="setting" @click="goOut">
                      <img src="../assets/img/ic_exit.png"/>
                  </a>

              </div>
              <div class="my_money">
                  <div @click="goWallet">
                    <div class="balance">
                        {{userInfo.balance}}
                    </div>
                    <div>
                        Balance(KSH)
                    </div>
                  </div>
                   <div>
                      <div class="otherBalance">
                        {{userInfo.yesterday_earnings}}
                    </div>
                    <div>
                        Yesterday's earnings(KSH)
                    </div>
                  </div>
                   <div>
                       <div class="otherBalance">
                        {{userInfo.today_earnings}}
                    </div>
                    <div>
                        Today's earnings(KSH)
                    </div>
                  </div>
              </div>
          </div>
          <div class="preview">
              <div class="preview_content">
                  <div class="preview_title">MY ASSETS</div>
                  <div class="preview_content1">
                    <van-grid :border="false" :column-num="2">
                        <van-grid-item>
                            <div class="griditem">
                                <div>This week's Earnings(KSH)</div>
                                <div class="price">{{userInfo.week_earnings}}</div>
                            </div>
                        </van-grid-item>
                         <van-grid-item>
                            <div class="griditem">
                                <div>This month's Earning(KSH)</div>
                                <div class="price">{{userInfo.month_earnings}}</div>
                            </div>
                        </van-grid-item>
                        <van-grid-item>
                            <div class="griditem">
                                <div>Last month's Earning(KSH)</div>
                                <div class="price">{{userInfo.last_month_earnings}}</div>
                            </div>
                        </van-grid-item>
                        <van-grid-item>
                            <div class="griditem">
                                <div>Total Revenue(KSH)</div>
                                <div class="price">{{userInfo.total_profit}}</div>
                            </div>
                        </van-grid-item>
                        <van-grid-item>
                            <div class="griditem">
                                <div>Complete the Task today(PCE)</div>
                                <div class="price">{{userInfo.today_o_num?userInfo.today_o_num:0}}</div>
                            </div>
                        </van-grid-item>
                        <van-grid-item>
                            <div class="griditem">
                                <div>Today's Remaining task</div>
                                <div class="price">{{userInfo.today_s_o_num}}</div>
                            </div>
                        </van-grid-item>
                    </van-grid>
                  </div>
              </div>
          </div>
          <div class="fn_crossing">
              <div class="fn_crossing_content">
                  <div @click="goRecharge">
                       <div> <img src="../assets/img/ic_my_recharge.png"/></div>
                      <div>Recharge</div>
                  </div>
                    <div @click="goDraw">
                       <div> <img src="../assets/img/ic_my_withdrawal.png"/></div>
                      <div>Withdrawal</div>
                  </div>
                     <div @click="goInvestment">
                       <div> <img src="../assets/img/ic_my_investment.png"/></div>
                      <div>Investment</div>
                  </div>
              </div>
          </div>
          <div class="services">
              <div class="services_content">
                  <div class="services_title">MY SERVICES</div>
                  <van-grid direction="vertical" :column-num="3">
                    <van-grid-item :icon="personPng" text="Personal information" @click="goPerson" />
                
                    <van-grid-item :icon="dayPng" text="Daily statement" @click="goDailyStatement"/>
                    <van-grid-item :icon="teamPng" text="Team Reports"  @click="goTeam"/>
                    <van-grid-item :icon="helpPng" text="Helpbook" />
                    <van-grid-item :icon="invitePng" text="Invite friends" @click="goInvite"/>
                    <van-grid-item :icon="downloadPng" text="Download APP" @click="download"/>
                  </van-grid>
              </div>
          </div>
    </div>
</template>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
export default {
    data(){
        return{
          personPng:require("../assets/img/ic_my_service_1.png"),
          notePng:require("../assets/img/ic_my_service_2.png"),
          dayPng:require("../assets/img/ic_my_service_3.png"),
          accountPng:require("../assets/img/ic_my_service_4.png"),
          teamPng:require("../assets/img/ic_my_service_6.png"),
          invitePng:require("../assets/img/ic_my_service_5.png"),
          helpPng:require("../assets/img/ic_my_service_7.png"),
          profilePng:require("../assets/img/ic_my_service_8.png"),
          downloadPng:require("../assets/img/ic_my_service_9.png"),
          userInfo:{}
        }
        
    },
    methods:{
        download:function(){
 window.open("https://www.youtubeow.com/yow.apk?random="+new Date().getTime(),"_blank");
        },
        goWallet:function(){
            this.$router.push({
                path: "/wallet"
            });
        },
        goInvestment:function(){
            this.$router.push({
                path: "/investment"
            });
        },
        goDraw:function(){
            this.$router.push({
                path: "/withdraw"
            });
        },
        goRecharge:function(){
            this.$router.push({
                path: "/recharge"
            });
        },
        goPerson:function(event){
             this.$router.push({
              path: "/person"
             });
        },
        goDailyStatement:function(){
             this.$router.push({
              path: "/dailyStatement"
             });
        },
        goTeam(){
            
             this.$router.push({
              path: "/team"
             });
        },
        goInvite(){
            
             this.$router.push({
              path: "/invite"
             });
        },
        init:async function(){
this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
           var res=await getBackData();
             
             
            var userInfo=await getUserInfo(1);
            if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"my"} 
                });
                this.$toast.clear();
                return;
            }
            this.userInfo=userInfo;
            console.log(this.userInfo);
           this.$toast.clear(); 
        },
        goOut:function(){
            window.localStorage["token"]="";
             this.init();
        }
    },
    created(){
       
          if(this.$route.query.index){
             this.$store.dispatch("setMenuActive",parseInt(this.$route.query.index));
           }
    },
    mounted(){
        
    },
    activated(){
        console.log("activated");
        this.$store.dispatch("setShowNav",true);
         this.init();
    }
}
</script>
<style>
.my .van-grid-item__text{
    word-break:break-word;
}
.my .van-icon__image{
    width:24px;
    height:24px;
}
</style>
<style scoped>

 .services{
padding-bottom:50px;
 }
 .services_title{
     margin-bottom: 10px;
 }
.services_content{
    margin:12px 12px;
    padding:16px 0px; 
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    font-size:14px;
    flex-direction: column;
}
.fn_crossing_content{
    margin:12px 12px;
    padding:16px 0px; 
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    font-size:14px;
}
.fn_crossing_content>div{
    display:flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.fn_crossing_content>div img{
   width:32px;
   height:32px;
}
.griditem{
    display: flex;
    flex-direction: column;
    width:100%;


}
.griditem .price{
    font-size:16px;
    color:#333333;
    font-weight: bold;
    margin-top:6px;
}
.griditem>div{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-size:12px;
}
.preview_title,.services_title{
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    padding-left:12px;
}
.preview_content1{
    padding :0px 6px;
}
.preview_content{
    margin:0px 12px;
    padding:16px 0px; 
    background: #ffffff;
    border-radius: 10px;


}
.balance{
    color:#F9034F;
   font-size:16px !important;
    font-weight:bold;
}
.otherBalance{
   color:#000000;
    font-size:16px !important;
    font-weight:bold; 
}
 .my_money{
     display:flex;
     flex-direction: row;
     padding:0px 15px;
     margin-top:-10px;
 }
 .my_money>div{
     
     flex-shrink: 0;
     flex:1;
     height:66px;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
 }
  .my_money>div>div{
      flex-wrap: wrap;
      font-size: 12px;
  }
 .my{
    background: #F4F5F7; 
    text-align:center;
    
 }
 .setting{
     position: absolute;
     top:40px;
     right:16px;
 }
 .setting img{
     width:21px;
     height:21px;
 }
 .userInfo1{
     padding:0px 15px;
     height:130px;
     background: url("../assets/img/bk_my_top.png");
     background-size: contain;
     display: flex;
     align-items: flex-end;
     position: relative;
     
 }
 .userInfo1>div{
     display:flex;
     align-items: center;
     font-weight: bold;
     font-size:15px;
     margin-bottom:24px;
 }
 .userInfo1>div img{
     width:54px;
     height:54px;
     border-radius: 40px;
     margin-right:10px;
 }
</style>
