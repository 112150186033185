<template>
   
    <div class="wallet">
               <div class="wallet_header">
                    <van-nav-bar    left-arrow  @click-left="onClickLeft">
                        <template #title>
                            <div class="title">MY WALLET</div>
                        </template>
                        
                    </van-nav-bar>
            </div>
            <div class="wallet_content">
                 <div class="wallet_preview">
                   <div class="preview">
                      <div>
                          Assets(KSH)
                      </div>
                      <div class="money">
                          {{userInfo.balance}}
                      </div>
                      <div>
                        <div @click="goRecharge" >RECHARGE ></div>
                        <div @click="goWithDraw">WITHDRAWAL ></div>
                      </div>

                   </div>
                 </div>
                 <div class="wallet_record">
                     <van-tabs v-model="active">
  <van-tab title="Recharge record">
      <div class="record">
          <van-list
  v-model="recharge_loading"
  :finished="recharge_finished"
  finished-text="No more data"
  :immediate-check="false"
  @load="onLoad"
>
 
  <div class="record_item" v-for="(item,index) in rechargeRecords" :key="index">
         
        <div  >
            <div>
                 <div>{{statusMap[item.status]}}</div>
             <div style="color:#F9034F">+{{item.money}}(KSH)</div>
            </div>
            <div>
                 <div>{{item.adddate}}</div>
             <div> </div>
            </div>
            
        </div>
   </div>
</van-list>
        
                 
      </div>
  </van-tab>
  <van-tab title="Withdrawal record">
        <div class="record">
                 <van-list
  v-model="draw_loading"
  :finished="draw_finished"
  finished-text="No more data"
  :immediate-check="false"
  @load="drawOnLoad"
>
         <div class="record_item" v-for="(item,index) in drawRecords" :key="index">
             <div  >
            <div>
                 <div>{{drawStatusMap[item.status_desc]}}</div>
             <div style="color:#F9034F">-{{item.money}}(KSH)</div>
            </div>
            <div>
                 <div>{{item.adddate}}</div>
             <div> </div>
            </div>
            
        </div>
         </div>
                 </van-list>        
      </div>
      
      
      </van-tab> 
</van-tabs>
                 </div>

            </div>
    </div>
</template>
<style>
.record_item{
    margin:14px 0px;
    background: #ffffff;
    border-radius: 10px;
    height:68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 10px;
    font-size: 14px;
    color:#333333;
}
.record_item>div{
     display: flex;
     flex-direction: column;
     flex-grow: 1; 
}

.record_item>div>div{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content:space-between;
}
.record_item>div>div:nth-child(2){
   
    margin-top:10px;
}
.record{
    padding:10px;
}
.wallet .van-tabs__nav{
    background: transparent !important;
}
</style>
<style scoped>
.preview .money{
    font-size: 20px;
    font-weight: bold;
}
.wallet_preview .preview>div:nth-child(1){
    flex-grow: 0;
    height:30px;
}
.wallet_preview .preview{
    height:135px;
    margin:12px;
    background: #F9034F;
     color:#ffffff;
    border-radius: 8px;
    display: flex;
    font-size: 14px;
    flex-direction: column;
    padding:10px;
}
.wallet_preview .preview>div:nth-child(3){
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    
}
.wallet_preview .preview>div{
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding:0px 15px;
}
.wallet_header{
    height:50px;
}
  .wallet{
      background: #F4F5F7; 
      width:100%;
      font-size:12px;
      overflow-y: auto;
  }
</style>
<script>
import {fetch,post} from '@/util/api'
import {getBackData,getUserInfo} from '@/util/api'
export default {
    data(){
        return {
            statusMap:{2:'fail',3:'Audit',1:'success'},
            drawStatusMap:{"未支付":'unpaid',"已支付":"Paid"},
            active:0,
            userInfo:{},
            rechargeRecords:[],
            drawRecords:[],
            page:0,
            drawPage:0,
            draw_finished:false,
            draw_loading:true,
            recharge_finished:false,
            recharge_loading:true,
            isFirst:true
        }
    },
    methods:{
        goWithDraw:function(){
            this.$router.push({
                        path: "/withdraw" 
                    });
        },
        goRecharge:function(){
            this.$router.push({
                        path: "/recharge"   
                    });
        },
        drawOnLoad:function(){
             console.log("drawOnLoad");
           this.getdrawRecord();
        },
        onLoad:function(){
           console.log("触发");
           this.getRechargeRecord();
        },
        onClickLeft:function(){
            this.$router.back(-1) ;
        },
        getWalletInfo:function(){

        },
        getdrawRecord:function(){
             this.drawPage++;
             if(!this.isFirst){
                this.$toast.loading({
                            duration:0,
                            message: 'loading...',
                            forbidClick: true,
                            loadingType: 'spinner' 
                            });
             }
           
            post("api/Transaction/getDrawRecord",{page_no:this.drawPage,state:'0'}).then(res=>{
                if(res.info&&res.info.length>0){
                    for(var i=0;i<res.info.length;i++){
                        this.drawRecords.push(res.info[i]);
                    }
                }else{
                    this.draw_finished=true;
                    this.draw_loading=false;
                }
                 this.draw_loading=false;
                 if(!this.isFirst){
                    this.$toast.clear();
                 }
                 this.isFirst=false
            })
        },
        getRechargeRecord:function(){
            this.page++;
             this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            post("api/Transaction/getRechargeRecord",{page_no:this.page,state:'0'}).then(res=>{
                if(res.info&&res.info.length>0){
                    for(var i=0;i<res.info.length;i++){
                        this.rechargeRecords.push(res.info[i]);
                    }
                }else{
                    this.recharge_finished=true;
                    this.recharge_loading=false;
                }
                 this.recharge_loading=false;
                 this.$toast.clear();
            })
        },
        init:async function(){
           this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            var userInfo=await getUserInfo();
          if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"withdraw"} 
                });
                this.$toast.clear();
                return;
            }
          this.userInfo=userInfo;
           this.$toast.clear();
          this.getRechargeRecord();
          this.getdrawRecord();
          
        }
    },
    activated(){
        this.init();
    }
}
</script>

