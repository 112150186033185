<template>
    <div class="business_hall_edit">
         <div class="business_hall_edit_header">
            <van-nav-bar    left-arrow @click-left="onClickLeft">
                <template #title>
                    <div class="title">Business Hall</div>
                </template>
                <template #right>
                    <div class="submit" @click="submit">
                        Confirm
                        
                    </div>
                </template>
            </van-nav-bar>
        </div>
        <div class="taskInfo"  style="padding-bottom:50px;">
            <div class="task_item" v-for="(i,index) in stepNum" :key="index">
                     <div class="upload">
                         <van-uploader v-model="fileObj[index].fileList" multiple :max-count="1" :after-read="afterRead" />
                     </div>
                     <div class="stepcontent">
                          <textarea placeholder="Please enter a step description" v-model="fileObj[index].descri" />
                     </div>
                     <div class="stepdelete">
                         <a><van-icon name="cross" color="#333333" /></a>
                     </div>
            </div>
            <div class="addStep">
                     <img src="../assets/img/ic_business_add.png" @click="addStep"/> 
            </div>
            
        </div>
    </div>
</template>
<style>
.addStep img{
    width:62px;
}
.addStep{
   display:flex;
   align-items: center;
   justify-content: center;
}
.business_hall_edit .van-cell{
    padding:10px 2px
}
.business_hall_edit .van-field__label{
    width:auto;
}
.business_hall_edit .van-uploader__upload{
    width:44px;
    height:44px;
}
.business_hall_edit .van-uploader__preview-image{
        width:44px;
    height:44px;
}
.business_hall_edit .van-uploader__mask-icon{
    font-size:15px;
}
</style>
<script>
import axios from 'axios' 
export default {
    props:{
        steps:Array
    },
    data(){
        return{
          value2:'',
          taskForm:{

          },
          fileList:[],
          message:'',
          stepNum:2,
          fileObj:[
              {fileList:[],descri:''},
              {fileList:[],descri:''},
              {fileList:[],descri:''},
              {fileList:[],descri:''},
              {fileList:[],descri:''},
              {fileList:[],descri:''},
              {fileList:[],descri:''}
          ],
          token:this.$store.getters.token,
        }

    },
    methods:{
        onClickLeft:function(){
          this.$emit('cancel');
        },
        submit:function(){
           this.$emit('confirm',this.fileObj);

        },
        addStep(){
           this.stepNum++;
        },
        validator(val) {
            console.log("晓燕");
            return /1\d{10}/.test(val);
        },
        afterRead(file) {
                        file.status = 'uploading';
            file.message = 'upload...';
             var image = new FormData()
             //api/User/UploadImg
              
             image.append('image', file.file);
             image.append('type', 3);
             image.append("token",this.token);
             var url=process.env.VUE_APP_BASE_API+"/api/User/UploadImg";
             console.log("url:",url);
             axios.post(url,image,{
            headers: {
              "Content-Type": "multipart/form-data",
              "X-Mx-ReqToken":this.token
            }
            }).then(res=>{
                console.log("res",res);
                if(res.data&&res.data.code==1){ 
                 file.status = 'success';
                file.message = 'success';
                file.url=res.data.url;
                }else{
                    file.status = 'failed';
                file.message = 'failed';
                }
                
            }).catch(e=>{
                 file.status = 'failed';
                file.message = 'failed';
            })
        }
    },
    mounted(){
        console.log("stepData1111111",this.steps);
    }
}
</script>
<style scoped>
.stepcontent textarea{
    width:100%;
     height:20px;
     border:1px solid #E5E5E5;
     border-radius:5px;
     padding:10px;
     font-size:0.25rem;
}
.stepdelete{
    width:24px;
    align-items: center;
    justify-content: flex-end !important;
}
.tasktips{
    color:#999999;
    font-size:12px;
    margin-top:12px;
    padding:0px 12px;
    text-align:left;
    line-height:18px;
    background:#F4F5F7;
    padding-bottom:50px ;
}

  .stepcontent{
  flex-grow:1;
  }
.task_item>div{
  
    display:flex;
    align-items:center;
    justify-content: center;
    font-size:0.2rem;
}
.task_item .upload{
    display:flex;
    justify-content: flex-start;
    margin-top:10px;
}
.task_item .conditions>a{
    display:flex;
    flex-wrap:nowrap;
    white-space: nowrap;
    border: 1px solid #E5E5E5;
    padding:2px;
    margin-bottom:8px;
    margin-right:5px;
    color:#cccccc;
}
.task_item .conditions{
    display:flex;
    flex-direction: row; 
     flex-wrap:wrap;
     margin-top:10px;

}
.task_item_title{
    color:#333333;
    display:flex;
   justify-content: flex-start;
  
   font-weight:bold;
   font-size:15px;
}
.task_item{
    margin:14px;
    padding:0px 14px;
    background: #ffffff;
    border-radius: 6px; 
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
    display:flex;
}
.taskInfo{
    background:#F4F5F7;
}
.business_hall_edit .submit{
  color:#EC054C;
  font-size: 16px;
}
.business_hall_edit_header .title{
    font-weight: bold;
    color:#333333;
}
.business_hall_edit{
    background: #F4F5F7;
}
</style>