<template>
    <div class="recharge">
          <div class="recharge_header">
              <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Investment</div>
                </template>
                <template #right>
                    <div class="submit" @click="goRecords" >
                        Records
                        
                    </div>
                </template>
            </van-nav-bar>

          </div>
          <div class="recharge_content">
                        <div class="invedt_item" :class="{active:item.selected}" v-for="(item,index) in productList" @click="hasSelect(index)" :key="item.id">
                     <div class="product">
                         <div>{{item.title}}</div>
                         <div> </div>
                     </div>
                     <div class="product_info">
                         <div class="daily_income">
                             <div>{{item.lilv*100}}%</div>
                             <div>Daily Income</div>
                         </div>
                          <div class="daily_info">
                              <div>
                                  <div>Investment time</div>
                                  <div class="days">{{item.time}}days</div>
                              </div>
                              <div>
                                  <div>Select this product</div>
                                  <div><input type="radio" :checked="item.selected"></div>

                              </div>
                          </div>
                     </div>

                </div>
 
              
          </div>
          <div  class="investment_footed">
                  <div class="input">
                      <div class="inputMoney">
                          <img src="@/assets/img/ic_money.png"/> 
                          <input v-model="money" placeholder="Please enter the storage amount"/>
                      </div>
                       <div class="income">
                          <div>{{profit}}</div>
                          <div>Estimated Income</div>
                       </div>

                  </div>
                  <div class="sureBtn">

                       <a @click="buy">Determine</a>
                  </div>

               </div>

    </div>
</template>
<style scoped>
.days{
    color:#333333;
    font-weight:bold;
}
.input{
    display:flex;
    flex-direction: column;
    margin-right:15px;
}
.input>div{
    align-items: center; 
}
.inputMoney,.income{
    flex-grow: 1;
    display:flex;
}
.income{
    justify-content: space-between;
}
.inputMoney>input{
    border:1px solid transparent;
    flex-grow: 1;
    padding-left:10px;
    font-size:14px;
}
.sureBtn>a{
    width:114px;
    height:38px;
    background:#F9034F;
    color:#ffffff;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:16px;
    border-radius: 5px;
}
.inputMoney>img{
    width:10px;
    height:16px;
}
.sureBtn{
    width:114px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inputMoney{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #E5E5E5; 
}
.investment_footed>div:nth-child(1){
    flex-grow: 1;
}
.investment_footed{
    height:2rem;
    position: fixed;
    bottom: 0px;
    background:#ffffff;
    right:0px;
    left:0px;
    z-index:1000;
    padding:10px 20px;
    display:flex; 
    flex-direction: row;
}
.daily_info>div:nth-child(1){
    border-bottom: 1px solid #E5E5E5;
}
.daily_info>div{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.income>div:nth-child(1){
    color:#F90E57;
    font-size:0.45rem;
}
.daily_income>div{
    display:flex;
    align-items: center;
    justify-content: center;
}
.daily_income>div:nth-child(2){
    color:#999999;
    font-size:0.25rem;
}
.daily_income>div:nth-child(1){
    color:#F9034F;
    font-weight: bold;
    font-size: 0.45rem;
}
.product>div:nth-child(1){
    color:#333333;
    font-size:0.35rem;
    
}
.product>div:nth-child(2){
    color:#666666;
    font-size:0.35rem;
    
}
.daily_income{
    width:100px;
    height:1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.invedt_item>div{
    flex-grow: 1;
}
.invedt_item{
    display:flex;
    flex-direction: column;
    background: #ffffff;
    border-radius:10px;
    padding:10px;
    margin-bottom: 10px;
}
.product{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom:1px solid #E5E5E5;
    margin-bottom: 10px;
    
}
.invedt_item{
    margin:0px 12px 12px;
}
.product_info{
    display: flex;
    flex-direction: row;
}
.daily_info{
    display:flex;
    flex-direction: column;
    flex-grow: 1;
}
.daily_info>div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>
<style scoped>
.expect_money{
    margin-top:10px;
    padding:0px 14px;
}
.pay{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:20px;
}
.pay a{
    width:224px;
    height:50px;
    font-size:20px;
    color:#ffffff;
    background: #F9034F;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.recharge_amout{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:0px 10px;
}
.recharge_item a>div{
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}
.recharge_item a>div:nth-child(1){
    color:#666666;
}
.recharge_item a>div:nth-child(2){
    color:#333333;
    font-size: 18px;
}
.recharge_item a>div:nth-child(3){
    color:#999999;
}
.recharge_item a{
height:100px;
margin:0px 5px 5px 5px;
background: #ffffff;
border-radius: 10px;
display:flex;
align-items: center;
justify-content: center;
color:#333333;
font-size: 14px;
flex-direction: column;
}
.define_money{
    background: #ffffff;
    border-radius: 10px;
    margin:0px 12px;
    display: flex;
    height:30px;
    align-items: stretch; 
    padding:10px;

}
.define_money input{
    border:1px solid transparent;
    flex-grow: 1;
    font-size: 15px;
    
}
.recharge_item{
    flex-basis: 33%;
    margin-bottom: 5px;
    
}
.recharge_content{
  margin-top:10px;
  padding-bottom:200px;
}
.recharge_header .title{
    font-weight: bold;
    color:#333333;
}
.recharge_header{
    height:50px;
}
  .recharge{
      background: #F4F5F7;
      height:100%;
      width:100%;
      font-size:12px;
  }
  .active>a>div{
      color:#F9034F !important;
  }
  .active>a{
      border:1px solid #F9034F;
      color:#F9034F !important;
  }
  .submit{
      color:#F9034F;
  }

</style>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
export default {
    data(){
        return{
            money:'',
            productList:[],
            prevIndex:null,
            profit:0
        }
    },
    methods:{
        goRecords:function(){
                this.$router.push({
                    path: "/record" 
                });
        },
        hasSelect:function(index){
            if(this.prevIndex!=null){
                this.productList[this.prevIndex].selected=false;
            }
            this.prevIndex=index;
            this.productList[index].selected=true;
        },
        onClickLeft:function(){
             this.$router.back(-1) 
        },
        init:async function(){
   this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            var userInfo=await getUserInfo();
          if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"invite"} 
                });
                this.$toast.clear();
                return;
            }
          this.userInfo=userInfo;
         
           
          this.getProduct();
        },
        getProduct:function(){
            //获取理财产品列表
            this.productList=[];

            post("/api/yuebao/getYuebaoList",{}).then(res=>{
                    var info=res.info;
                    info.sort((a,b)=>{
                        return parseInt(a.time) - parseInt(b.time)
                    });
                    for(var i=0;i<info.length;i++){
                        this.productList.push(Object.assign({},info[i],{selected:false}));
                    } 
                    this.$toast.clear();
            }).catch(e=>{
                this.$toast.clear();
            })
        },
        buy:function(){
            if(this.prevIndex==null){
                this.$toast("Please select financial products");
                return;
            }
            if(!this.money){
                this.$toast("Please enter the financial management amount");
                return;
            }
            this.$toast.loading({
            duration:0,
            message: 'buying...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            post("/api/yuebao/payYuebao",{yuebaoid:this.productList[this.prevIndex].id,money:this.money}).then(res=>{
                 this.$toast.clear();
                if(res.code==1){
                    this.$toast(res.code_dec)
                }else{
                    this.$toast(res.code_dec)
                }
               
            }).catch(err=>{
                  this.$toast("Purchase failed")
                  this.$toast.clear();
            })
        }
    },
      activated(){
          this.money='',
          this.prevIndex=null;
 this.$store.dispatch("setShowNav",false);
    this.init();
  },
    watch:{
            money(val,okaval){
                if(this.prevIndex!=null){
                    var product=this.productList[this.prevIndex];
                    var value=((val*product.lilv)*product.time).toFixed(2);
                    console.log("value",value);
                    this.profit=parseInt(val)+parseFloat(value);
                }
            }
    },
    mounted(){
     
    }
}
</script>

