<template>
    <div class="invite">
         <div class="person_header">
                <van-nav-bar    left-arrow  @click-left="onClickLeft">
                    <template #title>
                        <div class="title">Invite Friends</div>
                    </template>
                    
                </van-nav-bar>
            </div>
            <div style="position:relative">
          <img src="@/assets/img/bk_invite.png"/>  
          <div class="inviteContent">
             <div class="invite_title">
                    <div class="word_title">
                        Invite Friends
                    </div>
                    <div class="word_title_tip">
                        Receive large prizes
                    </div>
             </div>
             <div class="invite_img">
                 <img src="@/assets/img/bk_invite_figure.png"/>
                 <div class="goldimg">
                    <img  src="@/assets/img/bk_invite_gold.png"/>
                 </div>
                 
             </div>
              <div class="qrcode">
                <div class="qrcodeframe">
                     <div>
                         Your best friend-{{userInfo.phone}}
                     </div>
                     <div>
                         Invite you to join the Youtube Online work
                     </div>
                     <div class="imgqrcode">
                        <div class="qrcodeimg" ref="qrCodeUrl" id="qrCodeUrl"></div>
                     </div>
                     <div class="qrcodeInfo">
                       <div>
                           <div style="color:#333333;font-weight:bold">Referral code</div>
                           <div>{{userInfo.idcode}}</div>
                           <div class="copyLink"  @click="copyText(userInfo.idcode)">copy code</div>
                       </div>
                       <div style="width:30px;"></div>
                       <div>
                           <div style="color:#333333;font-weight:bold">
                               invitation link
 </div>
                           <div>
                               
{{link}}
                           </div>
                           <div class="copyLink" @click="copyText(link)">
                               copy link
                           </div>
                       </div>

                     </div>

                </div>
              </div>
              <div class="qrbtn">

                  <a  @click="saveImg">Save the QR code </a>
              </div>
               
          </div>
            </div>
    </div>
</template>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
 import QRCode from 'qrcodejs2'
export default {
    data(){
        return{
            userInfo:{},
            link:''
        }
       
    },
    methods:{
        copyText:function(text){
            var input = document.createElement("textarea");   // 直接构建input
                input.value = text;  // 设置内容
                document.body.appendChild(input);    // 添加临时实例
                input.select();   // 选择实例内容
                document.execCommand("Copy");   // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$toast(" copy success");
        },
        saveImg() {
        var canvasData = document.querySelector("#qrCodeUrl>canvas");
        var a = document.createElement("a");
        a.href = canvasData.toDataURL();
        a.download = "drcQrcode";
        a.click();
        },
        onClickLeft:function(){
                this.$router.back(-1) 
        },
        createQr:function(){

        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: this.link, // 需要转换为二维码的内容
            width: 150,
            height: 150,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
    
        },
        init:async function(){
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            var userInfo=await getUserInfo();
          if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"invite"} 
                });
                this.$toast.clear();
                return;
            }
          this.userInfo=userInfo;
          this.link=window.location.protocol+"//"+window.location.host+"/#/register?id_code="+this.userInfo.idcode;
          console.log("person 获取用户信息",this.userInfo,this.link);
          this.$toast.clear();
          this.createQr();
        }
    },
    activated(){
        document.querySelector("#qrCodeUrl").innerHTML=""
 this.$store.dispatch("setShowNav",false);
  this.init();
    },
    mounted(){
       
        
    }
}
</script>
<style scoped>
.qrbtn{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:18px;
    margin-top:15px;

}
.qrbtn>a{
    height:49px;
    width:276px;
    background: linear-gradient(to bottom right, #FFEA60,#FFD131);
    color:#FF4040;
    border-radius: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.qrcodeimg{
    height:150px;
}
.copyLink{
    margin-top:10px;
    color:#FF4040;
}
.qrcodeInfo>div:nth-child(1){
    width:4.83rem;
}
.qrcodeInfo{
    display:flex;
    margin-top:10px;
}
.imgqrcode img{
    width:164px;
    height:164px;
}
.imgqrcode{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:15px;
}
.qrcodeframe>div:nth-child(1){
    text-align: center;
    color:#333333;
    font-size: 14px;
    font-weight: bold;
}
.qrcodeframe>div:nth-child(2){
    text-align: center;
    color:#333333;
    font-size: 14px; 
    margin-top:5px;
}
.qrcode{
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-top:-100px;
}
.qrcodeframe{
    width:276px;
    height:306px;
    background:  #FFFFFF;
    box-shadow: inset 0px 0px 40px #FFBF65;
     border-radius: 20px;
     padding:20px;
}
.invite_title{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.word_title{
    font-size: 32px;
    font-weight:bold;
    color:#ffffff;
}
.word_title_tip{
    font-size:18px;
    color:#ffffff;
    margin-top:10px;
}
.invite_title{
    height:97px;
}
.invite_img>img{
  width:100%;
}
.goldimg{
    top:-102px;
    position:absolute;
}
.goldimg>img{
    width:100%;
}
.invite_img{
    position:relative;
}
.inviteContent{
    position:absolute;
    top:40px;
    right:0px;
    right:0px;
    bottom: 0px;
    width:100%;

}
  .invite{
      width:100%; 
      display:flex;
      flex-direction: column;
  }
  .invite>img{
      width:100%;
      height:100%;
  }
  
</style>
