<template>
    <div class="vip">
        <div class="vip_header">
                <div class="info">
                     VIP LEVEL
                </div>
        </div>
        <div class="vip_content">
            <div class="current_identity">
                <img src="@/assets/img/bk_vip_top.png"/>
                <div class="current_identity_word">
                    Your identity-{{userInfo==null?'Visitors':userInfo.useridentity}}
                </div>
                <div class="current_Task_level" @click="goLogin" >
                    {{userInfo==null?'Please log in first':'Daily Tasks'}}
                </div>
                <div class="current_Task_Num" v-if="userInfo!=null">
                    {{userInfo.number}}
                </div>
                 <div class="current_expire_time"  v-if="userInfo!=null">
                    Permanent validity
                </div>
            </div>

            <div class="levelItems">
               <div class="item item_level_other"  :class="'item_level'+(item.grade%7)" v-for="item in userGuide" :key="item.grade" @click="buyVip(item)">
                   <div class="level_info">
                       <div>{{item.name}}</div>
                       <div>{{item.amount>0?'KSH'+item.amount:'FREE'}}</div>
                   </div>
       
                   <div class="level_detail">
                       <div>
                           <div class="level_detail_1">{{item.commission}}</div>
                           <div class="level_detail_2">Per order(KSH)</div>
                       </div>
                       <div>
                          <div class="level_detail_1">{{item.number}}</div>
                           <div class="level_detail_2">Number of tasks</div>
  
                       </div>
                       <div>
                           <div class="level_detail_1">{{item.commission*item.number*365}}</div>
                           <div class="level_detail_2">Year Income</div>
                       </div>
                   </div>
               </div>

            




            </div>
        </div>
   <f-dialog :vshow="showTip" :title="title" :vmsg="msg" @ok="goBuy" @cancel="cancel"></f-dialog>
    </div>
</template>
<style scoped>
.level_detail_2{
  
    font-size:12px;
    margin-top:5px;
}
.level_detail_1{
  
    font-weight: bold;
    font-size: 18px;
}
.level_detail>div{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.level_detail{
    display:flex;
    flex-direction: row;
    flex-grow: 1;
}
.item_level_other{
        color:#65814E;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #DFF7CF, #AFE08F);
}
.item_level7 .level_info{
     border-bottom:1px solid #94CE6C;
}
.item_level1{
    color:#825639;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #F6E9D7, #E6C9B2);
   
}
.level_info{
    height:42px;
    flex-grow: 1;
}
.item_level1 .level_info{
  border-bottom:1px solid #DBB59D;
  
}

.item_level2{
    color:#707481;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #EEF3FF, #CAD1D9);
     border-bottom:1px solid #B3C2D3;
}
.item_level2 .level_info{
     border-bottom:1px solid #B3C2D3;
}

.item_level3{
    color:#856445;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #FEFAD8, #F2DFA1);
    
}
.item_level3 .level_info{
      border-bottom:1px solid #E4CC78;
}
.item_level4{
   color:#49737C;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #DEFCFD, #8DDAE2);
    
}
.item_level4 .level_info{
     border-bottom:1px solid #74B8BF;
}
.item_level5{
   color:#455F7E;
    border-radius: 10px;
     background-image: linear-gradient(to right bottom, #D3EAFF, #9EC0F0);
     
}
.item_level5 .level_info{
     border-bottom:1px solid #7FA7E0;
}
.item_level6{
    color:#674E81;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #D6CFF7, #BAAFF4);
    
}
.item_level6 .level_info{
     border-bottom:1px solid #A699E8;
}
.item_level7{
    color:#65814E;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #DFF7CF, #AFE08F);
  
}
.item_level7 .level_info{
      border-bottom:1px solid #94CE6C;
}

.item .level_info{
    height:42px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
   

}
.item .level_info>div{
    flex-grow: 1;
    display: flex;
    align-items: center;
}
.item .level_info>div:nth-child(1){
    padding-left:18px;
    font-size:18px;
}
.item .level_info>div:nth-child(2){
    padding-right:18px;
    font-size:18px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.item{
    display:flex;
    flex-direction: column;
}
.levelItems{
    margin:0px 4px;
    padding-bottom:100px;
}
.current_expire_time{
    position:absolute;
    top:54px;
    left:28px; 
    font-size:14px;
    color:#9E9E9E;
}
.current_Task_Num{
    position:absolute;
    bottom:50px;
    right:28px; 
    font-size:16px;
    color:#ffffff;
}
.current_identity_word{
    position:absolute;
    top:24px;
    left:28px;
    color:#ffffff;
    font-size:18px;
    font-weight: 500;
}
.current_Task_level{
     position:absolute;
    bottom:32px;
    right:28px; 
    font-size:16px;
    color:#ffffff;
}
.current_identity{
    margin:4px;
    position: relative;
}
.current_identity img{
    width:100%;
}
  .vip_header .info{
      display:flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      font-size:18px;
      color:#333333;
      font-weight: bold;
      background: #ffffff;
  }
  .vip{ 
      width:100%;
      background-color: #F4F5F7;
  }
  .item{
  display:flex;
  flex-wrap: wrap;
  margin:10px 14px 20px; 
  
}
.item>div{
  
  height:65px; 
}
</style>
<style></style>
<script>
 import {fetch,post} from '@/util/api'
  import {getBackData,getUserInfo} from '@/util/api'
  import fdialog from './dialog' 
export default {
     components:{fDialog:fdialog},
    data(){
        return{
           userGuide:[],
           userInfo:null,
           title:'prompt',
           msg:'',
           showTip:false,
           selectItem:null
        }
    },
    methods:{
        goBuy:function(){
           console.log("前往购买");
           this.$toast.loading({
            duration:0,
            message: 'buying...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            this.showTip=false;
            post("api/User/userBuyVip",{grade:this.selectItem.grade}).then(res=>{
                if(res.code==1){
                    this.$toast(res.code_dec);
                    this.showTip=false;
                    this.refreshUserInfo();
                }else{
                    this.$toast(res.code_dec);
                    if(res.code_dec=="Insufficient user balance!"){
                           this.$router.push({
                                path: "/recharge",
                                query:{src:'vip'} 
                            });
                    }
                }
            }).catch(err=>{
                this.$toast("Purchase failed");
            })
            

        },
        cancel:function(){
            console.log("cancel");
           this.showTip=false;

        },
        refreshUserInfo:async function(){
                this.$toast.loading({
                                        duration:0,
                                        message: 'loading...',
                                        forbidClick: true,
                                        loadingType: 'spinner' 
                                        });
                                         this.userInfo=await getUserInfo(1);
                                         this.$toast.clear();
        },
        goLogin:function(){
                 if(this.userInfo==null){
                    this.$router.push({
                                path: "/login" 
                            });
                 }else{
                     return;
                 }
        },
        init: async function (){
            this.$toast.loading({
                        duration:0,
                        message: 'loading...',
                        forbidClick: true,
                        loadingType: 'spinner' 
                        });
            var res=await getBackData();
            this.userGuide=res.info.UserGradeList;
             for(var i=0;i<this.userGuide.length;i++){
                var spread=this.userGuide[i].spread;
                var items=spread.split(",");
                var arr=[]
                for(var j=0;j<items.length;j++){
                    arr.push(items[j]+'%');
                }
                this.userGuide[i].spreadObj=arr.join(",");
             }
            console.log("userGuide",this.userGuide);
            this.userInfo=await getUserInfo();
            if(!this.userInfo){
                console.log("当前用户未登录");
            }
            console.log(this.userInfo);
            this.$toast.clear();
        },
        buyVip(item){
            if(item.grade<=this.userInfo.vip_level){
                this.$dialog.alert({
                    title: 'Failure prompt',
                    message: 'You are already '+this.userInfo.useridentity,
                    }).then(() => {
                    // on close
                    });
                    return;
            }
            console.log("buy vip",item);
           this.showTip=true;
           this.msg="Are you sure it will cost "+item.amount+"KSH to renew "+item.name+"?";
           this.selectItem=item;
        }
    },
    created(){

    },
        activated(){
        this.init();
          if(this.$route.query.index){
             this.$store.dispatch("setMenuActive",parseInt(this.$route.query.index));
           }
            this.$store.dispatch("setShowNav",true);
    },
    mounted(){

    }
}
</script>

