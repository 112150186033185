<template>
    <div class="person">
      <div class="person_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Daily statement</div>
                </template>
                
            </van-nav-bar>
       </div>
       <div class="person_content">
            <div class="preview">
                <div class="preview_total">
                  <div>
                      <div>Total revenue(KSH)</div>
                      <div class="money">{{dailyDatas.myTotalProfit}}</div>
                  </div>
                  <div>
                       <div>{{getToady()}}</div>
                       <div> </div>
                  </div>
                </div>
                <div class="line"></div>
                <div class="preview1">
                     <div>
                         <div class="title">My mission(PCE)</div>
                         <div class="money">{{dailyDatas.myTaskFinish}}</div>
                     </div>
                      <div>
                         <div class="title">Tasks completed by subordinates(PCE)</div>
                         <div  class="money">{{dailyDatas.branchTaskFinish}}</div>
                      </div>
                </div>
                <div class="preview1">
                     <div>
                         <div class="title">My mission benefits(KSH)</div>
                         <div class="money">{{dailyDatas.myTaskProfit}}</div>
                     </div>
                      <div>
                         <div class="title">Subordinate task benefits(KSH)</div>
                         <div class="money">{{dailyDatas.branchTaskProfit}}</div>

                      </div>
                </div>
            </div>
            <div class="preview_grid">
                 <div class="grid_title">
                    <div>Daily statement</div>
                     <div>Last 30 days</div>
                 </div>
                 <div class="grid_content">
                     <div class="grid_cell_wrap">
                         <div class="gridcell title">Quantity</div>
                         <div class="gridcell title">Task</div>
                         <div class="gridcell title">Subordinate</div>
                         <div class="gridcell title">Consumption</div>
                         <div class="gridcell title">Date</div>
                     </div>
                     <div class="grid_cell_wrap" v-for="(item,index) in daily" :key="index">
                         <div class="gridcell ">{{item.count}}</div>
                         <div class="gridcell ">{{item.task}}</div>
                         <div class="gridcell ">{{item.count}}</div>
                         <div class="gridcell ">{{item.consume}}</div>
                         <div class="gridcell ">{{item.date}}</div>
                     </div> 
                 </div>
            </div>
       </div>

     </div>
</template>
<style scoped>
.grid_cell_wrap{
    border-bottom: 1px solid #F5F5F5;
}
.grid_cell_wrap .title{
    color:#999999;
      font-size: 12px;
}
.gridcell{
    height:44px;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color:#333333;
}
.grid_cell_wrap{
    display: flex;
    flex-direction: row;
}
.grid_content{
        margin:12px 14px;
    border-radius: 5px;
    background: #Ffffff;
    overflow: hidden;
    font-size:16px;
}
.grid_title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:15px 12px 0px;
}
.grid_title>div:nth-child(1){
    font-weight: bold;
    font-size: 18px;
}
.grid_title>div:nth-child(2){
 
    font-size: 14px;
    color:#999999;
}

.preview .money{
    font-size: 16px;
}
.preview1>div>div{
    flex-grow: 1;
    display: flex; 
    align-items: center;
}
.preview1>div{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex-grow: 1;
}
.preview .title{
    font-size:11px;
}
.preview_total>div:nth-child(1){
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    
}
.preview_total>div:nth-child(1)>div:nth-child(2){
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:18px;
  font-weight:bold;
}
.preview_total>div:nth-child(2){
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}
.preview_total .money{

}
.preview_total{
    display:flex;
    flex-direction: row;
}
.preview_total>div{
    flex-grow: 1;
}
.line{
    height: 1px;
    width:100%;
    background: rgba(255, 255, 255, 0.4);
    margin-bottom: 10px;
}
.preview{
    display:flex;
    color:#ffffff;
}
.preview>div{
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
}
.preview>div:nth-child(2){
    flex-grow: 0;
}
 .person_header .title{
    font-weight: bold;
    color:#333333;
}
.person_header{
    height:50px;
}

.preview{
    margin:12px 14px;
    height:206px;
    border-radius: 5px;
    background-image: linear-gradient(to bottom right, #F9034F,#EC18D7);
    display: flex;
    flex-direction: column;
    padding:12px;
}
.person{
    font-size: 14px;
    background: #F4F5F7;
}
</style>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
 import {dateFormat} from '@/util/dateUtil'
export default {
    data(){
        return {
          dailyDatas:{},
          daily:[],
          userInfo:{}
        }
    },
    methods:{
        onClickLeft:function(){
 this.$router.back(-1) 
        },
        getDailyStatement:function(){
            post("/api/User/dailyReport",{}).then(res=>{
                 this.dailyDatas=res.data;
                 this.daily=res.data.daily;
                 this.$toast.clear();
            }).catch(err=>{
                this.$toast.clear();
            })
        }, 
        init:async function(){
          //  
          this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
           var res=await getBackData();
             
             
            var userInfo=await getUserInfo();
            if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"dailyStatement"} 
                });
                this.$toast.clear();
                return;
            }
            this.userInfo=userInfo;
            this.getDailyStatement();
             

        },
        getToady(){
            return dateFormat("YYYY-mm-dd",new Date());
        }
    },
    activated(){
        this.$store.dispatch("setShowNav",false);
         this.init();
        },
    mounted(){
       
    }
}
</script>

         
    
     