<template>
    <div class="notice">
        <div class="notice_header">
                <van-nav-bar    left-arrow  @click-left="onClickLeft">
                    <template #title>
                        <div class="title">{{title}}</div>
                    </template>
                    
                </van-nav-bar>
            </div>
            <div class="notice_content" v-html="video_content">
                

            </div>
    </div>
</template>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
export default {
    data(){
       return{
          video_content:"",
          title:''
       }
    },
    methods:{
        onClickLeft:function(){
            this.$router.back(-1) 
        },
        getNotice:function(){
              this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner',
            overlay:true
            });
           post("/api/common/getNoticeDetail",{id:this.id}).then(res=>{
               this.$toast.clear();
               this.video_content=res.data.content;
           })
        },
        init:async function(){
            this.getNotice();
        }
    },
      activated(){
          this.video_content="";
        this.$store.dispatch("setShowNav",false);
        
        this.title=this.$route.query.title;
        this.id=this.$route.query.id;
        this.init();
    },
}
</script>
<style scoped>
 .notice_content{
     margin:10px 10px 0px;
     
 }
.notice_header .title{
    font-weight: bold;
    color:#333333;
}
.notice_header{
    height:50px;
}
.notice{
     width:100%; 
     background: #f7f8fa;
}
</style>

