<template>
    <div class="notice">
        <div class="notice_header">
                <van-nav-bar    left-arrow  @click-left="onClickLeft">
                    <template #title>
                        <div class="title">Tutorials</div>
                    </template>
                    
                </van-nav-bar>
            </div>
            <div class="notice_content">
                <van-cell-group inset>
                  <van-cell :title="item.title" v-for="(item,index) in video_tutorials" :key="index" @click="goDetail(item)"   is-link />  
                </van-cell-group>

            </div>
    </div>
</template>
<script>
 import {fetch} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
export default {
    data(){
       return{
          video_tutorials:[]
       }
    },
    methods:{
        onClickLeft:function(){
            this.$router.back(-1) 
        },
        goDetail:function(item){
            this.$router.push({
                        path: "/noticedetail",
                        query:{id:item.id,title:item.title}  
                    });
        },
        init:async function(){
           this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner',
            overlay:true
            });
            var res= await getBackData();
            this.video_tutorials=res.info.videovTutorial;
            this.$toast.clear();
        }
    },
      activated(){
    this.$store.dispatch("setShowNav",false);
    this.init();
    },
}
</script>
<style scoped>
 .notice_content{
     margin-top:10px;
 }
.notice_header .title{
    font-weight: bold;
    color:#333333;
}
.notice_header{
    height:50px;
}
.notice{
     width:100%; 
     background: #f7f8fa;
}
</style>

