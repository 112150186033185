<template>
    <div class="invite">
         
            <div style="position:relative;overflow-x:hidden;height:30rem;">
          <img src="@/assets/img/bk_invite.png"/>  
          <div class="inviteContent">
             <div class="invite_title">
                    <div class="word_title">
                       MEMBERSHIP LEVEL
                    </div>
                    <div class="word_title">
                        AND BENEFITS
                    </div>
             </div>
             <div class="invite_img">
                 <img src="@/assets/img/bk_invite_figure.png"/>
                 <div class="goldimg">
                    <img  src="@/assets/img/bk_invite_gold.png"/>
                 </div>
                 
             </div>
             <div class="profit_content">
                 <div class="profit_items profit_title">
                     <div class="profit_item">
                              
                         level
                     </div>
                     <div class="profit_item">
                          Price    
                         
                     </div>
                     <div class="profit_item">
                             Quantity 
                         
                     </div>
                    <div class="profit_item">
                              
                         Commission
                     </div>
                      <div class="profit_item">
                              
                         YearIncome
                     </div>

                 </div>
                    <div class="profit_items" v-for="(item,index) in UserGradeList" :key="index">
                     <div class="profit_item">
                              
                         {{item.name}}
                     </div>
                     <div class="profit_item">
                           {{item.amount}}    
                         
                     </div>
                     <div class="profit_item">
                             {{item.number}}  
                         
                     </div>
                    <div class="profit_item">
                              {{item.commission}}  
                         
                     </div>
                                          <div class="profit_item">
                              {{item.commission*item.number*365}}  
                         
                     </div>

                 </div>
             </div>
                <div class="content">
                    <div class="team_des">
                           <img src="@/assets/img/ic_team_commission.png"/>
                           Team Commission

                    </div>
                    <div class="team_des_content">
 <br>
                        If you invite a friend and he earns 100KSH every day, you can get 12KSH.
                        <br>
                        <br>
If you invite friend A, friend A invites friend B, friend B earns 100KSH every day, you can get 6KSH
<br>
  <br>
If you invite friend A, friend A invites friend B, friend B invites friend C, friend C earns 100KSH every day, then you can get 3KSH
                  <br>
  <br>
                    </div>
                </div>



                      <div class="profit_content profit_spec" style="margin-top:0px;margin-bottom:200px">
                   <div class="spec_tip">
                       Tier 1 membership subscription commission
                   </div>
                   <div style=" overflow-x: auto;">
                 <div class="profit_spec_items profit_title">
                     <div class="profit_spec_item"  v-for="(item,index) in UserGradeList" :key="index">
                              
                          {{'VIP'+item.grade}}
                     </div>
                      

                 </div>
                  <div class="profit_spec_items ">
                     <div class="profit_spec_item"  v-for="(item,index) in UserGradeList" :key="index">
                              
                          {{item.vip_commission}}
                     </div>
                      

                 </div>
                   </div>
                   <div class="imgdes">
                   <img src="@/assets/img/ic_team_fy.png"/>
                   </div>
                    <!-- <div class="profit_items"  >
                       <div class="profit_item"  v-for="(item,index) in UserGradeList" :key="index">
                              
                          {{item.vip_commission}}
                     </div>

                 </div> -->
             </div>
          </div>
            </div>
    </div>
</template>
<style scoped>
.imgdes{ 
    margin:10px;
    box-shadow: 0px 4px 20px 0px #ECECEC;
}
.imgdes>img{
    width:100%;
    margin-top:0.3rem;
    margin:0px 10px;
}
.profit_spec .profit_title{
    margin-bottom: 0.2rem;
}
.profit_title>.profit_spec_item{
    color:#EC054C;
    font-size: 0.4rem;
    font-weight:bold;
    text-align: center;
}
.spec_tip{
    font-size:0.4rem;
    display:flex;
    align-items: center;
    justify-content: center;
    color:#333333;
    font-weight: bold;
    margin-bottom:0.2rem;
    padding:0.3rem 0px;
    border-bottom: 1px solid #D8D8D8;
}
.profit_spec{
    
       
        padding-bottom: 0.3rem;
}
.profit_spec_items{
    display:flex;
    flex-direction: row;
text-align: center;
    font-size:0.35rem;
}
.profit_spec_item{
    width:1.5rem;
    white-space:nowrap;
    display: block;
    flex-shrink:0;
}
.profit_spec .profit_item{
    width:20%;
}
  .profit_content{
      margin:-2.5rem 0.4rem 0px;
      border-radius: 0.2rem;
      background: #ffffff;
      position: relative;
      z-index: 1;
  }

    .content{
      margin:0.5rem 0.4rem 0px;
      border-radius: 0.2rem;
      position: relative;
      z-index: 1;
      flex-direction: column;
  }
  .profit_items:nth-last-of-type(1){
       border-bottom:1px solid transparent !important;
  }
  .profit_items{
      display:flex;
      flex-direction: row;
      font-size:0.35rem;
      border-bottom:1px solid #D8D8D8;
     
  }
  .profit_item{
      height:1.11rem
  }
  .profit_items>div:nth-child(3){
       flex-basis: 11%;
  }
  .profit_items>div{
    flex-grow: 1;
    flex-basis: 20%;
    display:flex;
    align-items: center;
    justify-content: center;
  }
</style>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
 import QRCode from 'qrcodejs2'
export default {
    data(){
        return{
            userInfo:{},
            link:'',
            profits:[],
            config:{},
            UserGradeList:[]
        }
       
    },
    activated(){
 if(this.$route.query.index){
             this.$store.dispatch("setMenuActive",parseInt(this.$route.query.index));
           }
    },
    methods:{
        copyText:function(text){
            var input = document.createElement("textarea");   // 直接构建input
                input.value = text;  // 设置内容
                document.body.appendChild(input);    // 添加临时实例
                input.select();   // 选择实例内容
                document.execCommand("Copy");   // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$toast(" copy success");
        },
        saveImg() {
        var canvasData = document.querySelector("#qrCodeUrl>canvas");
        var a = document.createElement("a");
        a.href = canvasData.toDataURL();
        a.download = "drcQrcode";
        a.click();
        },
        onClickLeft:function(){
                this.$router.back(-1) 
        },
        init:async function(){
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            var config=await getBackData();
             this.UserGradeList=config.info.UserGradeList;
             console.log(this.UserGradeList);
          
          this.$toast.clear();
        }
    },
    activated(){
 this.$store.dispatch("setShowNav",true);
 this.init();
    },
    mounted(){
        
        
    }
}
</script>
<style scoped>
.team_des>img{
    width:0.55rem;
}
.team_des{
    font-size:0.5rem;
}
.team_des_content{
    font-size:0.35rem;
}
.team_des,.team_des_content{
    color:#ffffff;
}
.qrbtn{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:18px;
    margin-top:15px;

}
.qrbtn>a{
    height:49px;
    width:276px;
    background: linear-gradient(to bottom right, #FFEA60,#FFD131);
    color:#FF4040;
    border-radius: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.qrcodeimg{
    height:150px;
}
.copyLink{
    margin-top:10px;
    color:#FF4040;
}
.qrcodeInfo>div:nth-child(1){
    width:200px;
}
.qrcodeInfo{
    display:flex;
    margin-top:10px;
}
.imgqrcode img{
    width:164px;
    height:164px;
}
.imgqrcode{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:15px;
}
.qrcodeframe>div:nth-child(1){
    text-align: center;
    color:#333333;
    font-size: 14px;
    font-weight: bold;
}
.qrcodeframe>div:nth-child(2){
    text-align: center;
    color:#333333;
    font-size: 14px; 
    margin-top:5px;
}
.qrcode{
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-top:-100px;
}
.qrcodeframe{
    width:276px;
    height:306px;
    background:  #FFFFFF;
    box-shadow: inset 0px 0px 40px #FFBF65;
     border-radius: 20px;
     padding:20px;
}
.invite_title{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.word_title{
    font-size: 32px;
    font-weight:bold;
    color:#ffffff;
}
.word_title_tip{
    font-size:18px;
    color:#ffffff;
    margin-top:10px;
}
.invite_title{
    height:97px;
}
.invite_img>img{
  width:100%;
}
.goldimg{
    top:-102px;
    position:absolute;
}
.goldimg>img{
    width:100%;
}
.invite_img{
    position:relative;
}
.inviteContent{
    position:absolute;
    top:40px;
    right:0px;
    right:0px;
    bottom: 0px;
    width:100%;

}
  .invite{
      width:100%; 
      display:flex;
      flex-direction: column;
      background: #FF2D46;
  }
  .invite>img{
      width:100%;
      height:100%;
  }
  
</style>
