<template>

<div class="login">
      <div class="person_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title"> </div>
                </template>
                
            </van-nav-bar>
        </div>
        <div>
              <div class="login_word">

                 Youtube online work 
              </div>
              <div class="login_panel">
                        
                <div class="input_item">
                    <div>Account</div>   
                     <div>
                       <a>+254</a>
                       <input class="input inputspec" v-model="username" placeholder="Please enter your mobile phone number"/>
                       </div>   
                             
                </div>
                <div class="input_item">
                    <div>Password</div>   
                     <div>
                      
                       <input class="input" v-model="password" type="password" placeholder="Please enter the login password"/>
                       </div>   
                             
                </div>
                <div class="rember">
                  <input type="checkbox" v-model="checked"/> 
                  Remember username/password
                </div>

                <div class="loginbtn">
                       <a @click="onSubmit">Login</a>
                </div>

                <div class="tip">
                 <a>No Youtubeow account? </a>
                 <a @click=goRegister>Register</a>
                   
                </div>

              </div>

        </div>

</div>
 
</template>
<style scoped>
.tip{
  margin-top:10px;
}
.tip>a:nth-child(1){
  color:#999999;
}
.tip>a:nth-child(2){
  color:#333333;
}
.loginbtn{
  margin-top:20px;
}
.loginbtn>a{

  height:44px;
  width:92px;
  color:#ffffff;
  background: #F9034F;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:16px;
  font-weight: bold;
}
.rember{
  display:flex;
  flex-direction: row;
  align-items: center; 
  margin-top:-10px;
}
.input_item{
  border-bottom: 1px solid #E5E5E5;
  padding-bottom:10px;
  margin-bottom: 20px;
}
.inputspec{
 margin-left:20px;
}
.input{
  flex-grow: 1;
  border:1px solid transparent; 
  font-size:14px;

}
.input_item>div:nth-child(1){
  font-size:15px;
  font-weight:bold;
}
.input_item>div:nth-child(2){
   margin-top:5px;
   display: flex;
   flex-direction: row;
}
.input_item>div:nth-child(2)>a{
  width:50px;
  background: #F9034F;
  padding:5px;
  display: flex;
  color:#ffffff;
  border-radius: 2px;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;

}
.input_item>div:nth-child(2)>a::after{
   position: absolute !important;
    top: 50%;
    right: 5px;
    margin-top: -5px;
    border: 3px solid;
    border-color: transparent transparent #ffffff #ffffff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0.8;
    content: '';
    position: relative;
}
.login_panel{
  margin:24px;
}
.person_header .title{
    font-weight: bold;
    color:#333333;
}
.person_header{
    height:50px;
}
.login_word{
  margin-left:24px;
   margin-top:25px;
   font-size:0.65rem;
}
.login_word img{
  width:137px;
}
.login{
  font-size:12px;
}
</style>
<script>
import {fetch} from '@/util/api'
export default {
    data(){
        return {
            username: '', 
            password: '',
            checked:false
        }
    },
    methods:{
      onClickLeft:function(){
  this.$router.back(-1)
      },
      goRegister:function(){
        this.$router.push({
              path: "/register"  
        });
      },
      onSubmit(values) {
      console.log('submit', values);
      if(!this.username){
        this.$toast("Please enter your mobile phone number");
        return;
      }
      if(this.username){
         if(this.username.length!=9){
              this.$toast("Phone format error");
              return;
         }
         if(isNaN(this.username)){
                         this.$toast("Phone format error");
              return;
         }
      }
      if(!this.password){
        this.$toast("Please enter the login password");
        return;
      }
      this.$toast.loading({
            duration:0,
            message: 'logining...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
        fetch("api/User/Login",{username:this.username,password:this.password,lang:'en'}).then(res=>{
            console.log("res",res);
            if(res.code==1){
              if(this.checked){
                window.localStorage["user"]=this.username;
                 window.localStorage["pwd"]=this.password;
              }else{
                window.localStorage["user"]="";
                window.localStorage["pwd"]="";
              }
              this.$store.dispatch('setToken',res.info);
              console.log("getter",this.$store.getters.userInfo);
              console.log("登录成功，回跳地址:"+this.src);
              this.$toast.clear();
              if(!this.src){
              this.$router.push({
                      path: "/",
                  });
              }else{
              this.$router.push({
                      path: "/"+this.src,
                  });
              }
            }else{
              this.$toast(res.code_dec);
            }


        }).catch(err=>{
           this.$toast.clear();
        })
      },
    },
    activated(){
      if(window.localStorage["user"]&&window.localStorage["pwd"]){
                this.username=window.localStorage["user"];
                this.password=window.localStorage["pwd"];
                this.checked=true;
                
      }

       this.$store.dispatch("setShowNav",false);
      this.src=this.$route.query.src;

      //判断如果是已经登录状态，则直接

    },
    mounted(){
      console.log("getter",this.$store.getters.userInfo);
     
    }
}
</script>

