<template>
    <div class="person">
      <div class="person_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Personal information</div>
                </template>
                
            </van-nav-bar>
        </div>
        <div class="person_content">
            <div class="person_item person_info">
                    <div class="person_name">Head portrait</div>
                    <div class="person_img">
          
                        <img :src="userInfo.header?userInfo.header:defaultHeader"/>
                        <input type="file" class="changeHead" ref="inputer" @change="updateHead"/>
                    </div>
                    <i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i>
            </div>

            <div class="person_item">
                <van-cell title="Mobile number"   :value="userInfo?userInfo.phone:''"/>
                
                <van-cell title="Login password" is-link value="Click Settings" @click="showPwdPage"/>
                
                <van-cell title="Empty the cache" is-link @click="clearCache" />
            </div>

        </div>
    <van-popup v-model="showPwd" position="right" :style="{ height: '100%',width: '100%' }">
       <div class="person_header">
            <van-nav-bar    left-arrow  @click-left="onClickPopLeft">
                <template #title>
                    <div class="title">Login password</div>
                </template>
                
            </van-nav-bar>
        </div>
         <div class="person_item">
             <van-form @submit="onSubmit">
                  <van-field
                  label-width="150px"
                    v-model="oldPwd"
                    input-align="right"
                    name="用户名"
                    type="password"
                    label="Original login password"
                    placeholder="Click enter"
                    :rules="[{ required: true, message: 'Please enter the original password' }]"
                />

                 <van-field
                  label-width="150px"
                    v-model="newPwd"
                    type="password"
                    input-align="right"
                    name="用户名"
                    label="New login password"
                    placeholder="Click enter"
                    :rules="[{ required: true, message: 'Please enter a new password' }]"
                />

                <van-field
                  label-width="150px"
                    v-model="cnewPwd"
                    type="password"
                    input-align="right"
                    name="用户名"
                    label="Confirming passwords"
                    placeholder="Click enter"
                    :rules="[{ required: true, message: 'Please enter the new password again' }]"
                />
             </van-form>
          
         </div>
            <a class="pwdSubmit" @click="pwdSubmit">
                 Submit
             </a>
    </van-popup>


        <van-popup v-model="fundShowPwd" position="right" :style="{ height: '100%',width: '100%' }">
       <div class="person_header">
            <van-nav-bar    left-arrow  @click-left="onClickFundPopLeft">
                <template #title>
                    <div class="title">Fund password</div>
                </template>
                
            </van-nav-bar>
        </div>
         <div class="person_item">
             <van-form @submit="onSubmit">
                  <van-field v-if="userInfo.is_fund_password==1"
                  label-width="150px"
                    v-model="foldPwd"
                     type="password"
                    input-align="right"
                    name="用户名"
                    label="Original fund password"
                    placeholder="Click enter"
                    :rules="[{ required: false, message: 'Please enter the original password' }]"
                />

                 <van-field
                  label-width="150px"
                    v-model="fnewPwd"
                     type="password"
                    input-align="right"
                    name="用户名"
                    label="New fund password"
                    placeholder="Click enter"
                    :rules="[{ required: true, message: 'Please enter a new password' }]"
                />

                <van-field
                  label-width="150px"
                    v-model="fcnewPwd"
                     type="password"
                    input-align="right"
                    name="用户名"
                    label="Confirming passwords"
                    placeholder="Click enter"
                    :rules="[{ required: true, message: 'Please enter the new password again' }]"
                />
             </van-form>
          
         </div>
            <a class="pwdSubmit" @click="FundPwdSubmit">
                 Submit
             </a>
    </van-popup>
 <!-- 编辑头像弹层 -->
<van-popup
           v-model="isShowUpdateAvatar"
           style="height: 100%"
           position="bottom">
    <update-avatar v-if="isShowUpdateAvatar" @close="isShowUpdateAvatar = false" @cancel="avarCancel" @success="avarSuccess" :img="img" />
</van-popup>
<!-- 编辑头像弹层 -->

    </div>
</template>
<style>
.pwdSubmit{
background:#F80050;
color:#ffffff;
margin:20px 14px;
border-radius:5px;
 height:44px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.person{
    font-size:14px;
    color:#333333;
}
.person .van-uploader__upload{
    margin:0 0px 0px 0 !important;
}
</style>
<style scope>
.changeHead{
    position:absolute;
    right:0px;
    width:42px;
    height:42px;
   opacity: 0;
}
.person .van-popup--right{
    background:#F4F5F7;
}
.person_img img{
    width:1.16rem;
    height:1.16rem;
    border-radius:21px;
}
.person_img{
    position: relative;
    flex-grow:1;
    display:flex;
    justify-content: flex-end;
}
.person_info{
        display: flex;
    flex-direction: row;
 align-items: center;
    justify-content: space-between;
        padding:12px;
}
.person_item{
    margin:12px 14px;
    border-radius: 5px;
    background: #Ffffff;

    overflow: hidden;
   
    font-size:16px;
}
.person_header .title{
    font-weight: bold;
    color:#333333;
}
.person_header{
    height:50px;
}
.person_content{

}
.person{
    background: #F4F5F7; 
    width:100%;
}
</style>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
import updateAvatar from './update-avatar.vue'
export default {
    components:{updateAvatar},
    data(){
        return{
            showEdit:false,
           fileList: [],
           showPwd:false,
           oldPwd:'',
           newPwd:'',
           cnewPwd:'',
           foldPwd:'',
           fnewPwd:'',
           fcnewPwd:'',
           userInfo:{},
           fundShowPwd:false,
           headerImage:"",
           isShowUpdateAvatar:false,
           img:null,
           defaultHeader:require("@/assets/img/user_default.png")
        }
    },
    methods:{
        avarSuccess:function(){
              this.init();
              this.isShowUpdateAvatar=false
               this.img=null;
        },
        avarCancel:function(){
            this.isShowUpdateAvatar=false
            this.img=null;
        },
        updateHead:function(){
            let inputDOM = this.$refs.inputer;
        // 通过DOM取文件数据
        var file = inputDOM.files[0];
        self=this; 
         this.isShowUpdateAvatar = true
         this.img = window.URL.createObjectURL(file)
         
        },
        newHeaderImage(newImg){
        this.headerImage = newImg;
        },
        clearCache:function(){
           window.localStorage.clear();
            this.init();
        },
            FundPwdSubmit:function(){

            if(!this.foldPwd&&this.userInfo.is_fund_password==1){
                  this.$toast("Please enter the original password");
                return;
            }
            if(!this.fnewPwd){
                  this.$toast("Please enter a new password");
                return;
            }
            if(!this.fcnewPwd){
                  this.$toast("Please enter the new password again");
                return;
            }
            if(this.fnewPwd!=this.fcnewPwd){
                this.$toast("The two new passwords are inconsistent");
                return;
            }
            
            this.$toast.loading({
            duration:0,
            message: 'Submitting...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
             post("/api/user/setuserinfo",{o_payword:this.foldPwd,n_payword:this.fnewPwd,r_payword:this.fcnewPwd}).then(res=>{
                 this.$toast.clear();
                 if(res.code==1){
                     this.$toast(res.code_dec);
                     this.fundShowPwd=false;
                 }else{
                       this.$toast(res.code_dec);
                 }
             }).catch(err=>{
                  this.$toast.clear();
             })
        },
        showFundPwdPage:function(){
            this.fundShowPwd=true;
        },
        pwdSubmit:function(){

            if(!this.oldPwd){
                  this.$toast("Please enter the original password");
                return;
            }
            if(!this.newPwd){
                  this.$toast("Please enter a new password");
                return;
            }
            if(!this.cnewPwd){
                  this.$toast("Please enter the new password again");
                return;
            }
            if(this.newPwd!=this.cnewPwd){
                this.$toast("The two new passwords are inconsistent");
                return;
            }
            
            this.$toast.loading({
            duration:0,
            message: 'Submitting...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
             post("/api/user/setuserinfo",{o_password:this.oldPwd,n_password:this.newPwd,r_password:this.cnewPwd}).then(res=>{
                 this.$toast.clear();
                 if(res.code==1){
                     this.$toast(res.code_dec);
                     this.showPwd=false;
                 }else{
                       this.$toast(res.code_dec);
                 }
             }).catch(err=>{
                  this.$toast.clear();
             })
        },
        onClickFundPopLeft:function(){
          this.fundShowPwd=false;
        },
        onClickPopLeft:function(){
          this.showPwd=false;
        },
        onSubmit:function(){

        },
        onClickLeft:function(){
            this.$router.back(-1) 
        },
        showPwdPage:function(){
            this.showPwd=true;
        },
        init:async function(){
             this.$toast.clear();
        this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
          var userInfo=await getUserInfo(1);
          if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"person"} 
                });
                this.$toast.clear();
                return;
            }
          this.userInfo=userInfo;
          console.log("person 获取用户信息",this.userInfo);
          this.$toast.clear();
        }
    },
    created(){
     
    },
    mounted(){
 this.init();
  this.$store.dispatch("setShowNav",false);
    }
}
</script>

