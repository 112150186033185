<template>
    <div class="task_detail">
        <div class="task_detail_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">TASK DETAILS</div>
                </template>
                        
            </van-nav-bar>
        </div>
        <div class="task_detail_content">
             <div class="task_user_info">
                   <div class="user_head">
                      
                   </div>
                   <div class="user_info">
                      <div>Demand side:{{info.f_username}}</div>
                      <div>{{info.end_time}} Release</div>

                   </div>
             </div>
             <div class="task_info">
    <div class="receive_item receive_item_doing" v-if="!userTask.status||userTask.status==1" >
                <div class="info">
                    <div class="title">
                        {{info.title}}
                    </div>
                    <div class="title_descri">

                      {{info.content}}
                    </div>
                    <div class="otherInfo">
                        <div>
                            <div>Start</div>
                            <div>{{info.add_time}}</div>
                        </div>
                        <div>
                            <div>Deadline</div>
                            <div>{{info.end_time}}</div>

                        </div>
                        <div>
                             <div>Unit Price</div>
                            <div class="unitprice">KSH{{info.reward_price}}</div>
                        </div>

                    </div>
                    <div class="fnlist">
                        <div @click="openUrl(info.link_info)">Open the link</div>
                        <div @click="copyText(info.link_info)">Copy the link</div>
                    </div>
                </div>
                
                 <div class="submit" v-if="userTask.status&&userTask.status==1">
                    <div>
<van-uploader preview-size="48px" v-model="userTask.fileForm" :after-read="afterRead" />

                    </div>
                    <div>
                       <a @click="submit(userTask)">Submit</a>

                    </div>
                 </div>
                 <div class="submit" v-if="!userTask.status">
                     <div style="flex-grow:1;justify-content:flex-end;display:flex;">
                       <a @click="receive(info)">receiving</a>

                    </div>
                 </div>
              </div>


  <div class="receive_item receive_item_checking" v-if="userTask.status==2" >
                <div class="info">
                    <div class="title">
                        {{info.title}}
                    </div>
                    <div class="title_descri">

                       {{info.content}}
                    </div>
                    <div class="otherInfo">
                        <div>
                            <div>Submission time</div>
                            <div>{{userTask.add_time}}</div>
                        </div>
                        <div>
                            <div>Failure reason</div>
                            <div>expire/Audit failed</div>

                        </div>
                         

                    </div>
                  
                </div>
                

              </div>


             </div>
             <div class="task_step">

<van-tabs v-model="active">
  <van-tab title="Task steps"> 
      <div class="stepwrap">
        <div v-for="(item,index) in info.task_step" :key="index" class="stepItem">
             <div class="stepword">
               step {{index+1}}:{{item.describe}}
             </div>
             <div class="stepImg">
                 <img :src="item.img"/>
             </div>
        </div>
      </div>
  </van-tab>
  <van-tab title="Audit sample">
          <div class="stepwrap">
               <div class="stepItem stepImg" v-for="(item,index) in info.examine_demo" :key="index">
              <img :src="item"/>
               </div>
          </div>      
 </van-tab> 
</van-tabs>

             </div>
        </div>
    </div>
</template>
<style>
.task_detail .van-tabs__nav{
    background:transparent !important;
}
</style>
<style scoped>
.receive_item_checking{
   
    height:125px !important; 
}
.stepItem{
    margin-bottom:10px;
    background:#D8D8D8;
    padding:10px 10px;

}
.stepword{
    font-size:15px;
    font-weight:bold; 
    margin-bottom:10px;
}
.stepwrap{
    margin:15px  0px;
}
.stepImg img{
    width:100%;
}
.task_user_info{
    display:flex;
    flex-direction:row; 
    margin:10px 14px;
}
.user_info>div{
    flex-grow:1;
}
.user_info>div:nth-child(1){
    color:#333333;
    font-size:18px;
    font-weight:bold;
}
.user_info{
    display:flex;
    flex-direction:column;
    margin-left:10px;

}
.user_head{
    width:60px;
    height:60px;
    background:#cccccc;
    
    border-radius:28px;
}
.task_detail .title{
   font-size:16px;
   color:#333333;
   font-weight:bold;
   justify-content: flex-start;
   text-align: left;
}
.task_detail .title_descri{
   font-size:14px;
   color:#999999;
   justify-content: flex-start;
   text-align: left;
   margin-top: 8px;
}
.unitprice{
    color:#F9034F !important;
}
.fnlist{
    display: flex;
    flex-direction: row;
    margin-top:16px;
}
.fnlist>div{
    color:#00D5CD;
}
.fnlist>div:nth-child(1){
    padding-right:20px;
    border-right:1px solid #00D5CD;
}
.fnlist>div:nth-child(2){
    padding-right:20px;
     padding-left:20px;
}
.receive_item_doing .info{
    border-bottom:1px solid #E5E5E5;
}
.receive_item{
    margin:12px;
    border-radius: 10px;
    padding:12px 12px 0px 12px;
    background: #ffffff;
    font-size:14px;
    overflow: hidden;
    height:203px;
    display:flex;
    flex-direction: column;
}
.receive_item .info{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
}
.receive_item .submit{
    height:60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.receive_item .info>div{
    width:100%;
}
.receive_item .submit a{
    display: flex;
    align-items: center;
    justify-content: center;
    background:#F80050;
    color:#ffffff;
    width:76px;
    border-radius: 3px;
    height:28px; 
}
.otherInfo{
    display:flex;
    flex-direction:row; 
    margin-top: 14px;
}
.otherInfo>div{
    flex-grow: 1;
}
.otherInfo>div>div:nth-child(1){
  color:#666666;
  font-weight: 500;
}
.otherInfo>div>div:nth-child(2){
  color:#333333;
  margin-top: 6px;
  font-weight: 500;
}

.otherInfo>div>div{
    display: flex;
    justify-content: flex-start;
    text-align: left;
}
    .task_detail_header{
        height:50px;
    }
  .task_detail{
      background: #F4F5F7;
      height:100%;
      width:100%;
      font-size:12px;
  }
</style>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
  import axios from 'axios'
export default {
    data(){
        return {
            active:0,
            info:{}, 
            userTask:{},
           taskLists:{
               task_id:1,
               reward_price:10.00,
               add_time:'2021-10-28',
               end_time:'2021-10-28',
               group_info:'11121qa',
               title:'tiktok',
               fileForm:[],
               
           },
            token:this.$store.getters.token,
        }
    },
    activated(){
           console.log("tem actived"); 
           this.init();
            this.$store.dispatch("setShowNav",false);
            this.order_id=this.$route.query.order_id;
       },
    methods:{
          receive:function(item){
            this.$toast.loading({
            duration:0,
            message: 'receiving...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
           post("api/task/receiveTask",{id:item.id}).then(res=>{
               console.log(res);
               this.$toast.clear();
               if(res.code==1){
                   this.$toast(res.code_dec);
                   this.order_id=res.orderId;
                   this.getTaskInfo();
               }else{
                  this.$toast(res.code_dec); 
               }
           }).catch(e=>{
               setTimeout(()=>{
                   this.$toast.clear();
               },1500)
               
           })
        },
         openUrl:function(url){
           window.open(url,"_blank");
         },
         copyText:function(text){
                var input = document.createElement("textarea");   // 直接构建input
                input.value = text;  // 设置内容
                document.body.appendChild(input);    // 添加临时实例
                input.select();   // 选择实例内容
                document.execCommand("Copy");   // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$toast(" copy success");
        },
         afterRead(file){
            console.log(file);
             file.status = 'uploading';
            file.message = 'uploading';
             var image = new FormData()
             //api/User/UploadImg
              
             image.append('image', file.file);
             image.append('type', 3);
             image.append("token",this.token);
             var url=process.env.VUE_APP_BASE_API+"/api/User/UploadImg";
             console.log("url:",url);
             axios.post(url,image,{
            headers: {
              "Content-Type": "multipart/form-data",
              "X-Mx-ReqToken":this.token
            }
            }).then(res=>{
                console.log("res",res);
                if(res.data&&res.data.code==1){
              //  this.taskForm.examine_demo.push(res.data.url);
              file.url=res.data.url;
                 file.status = 'success';
                file.message = 'success';
                }
                
            }).catch(e=>{
                 file.status = 'failed';
                file.message = 'failed';
            })
       },
       submit:function(item){
          console.log(item);
          var form={
            order_id:item.id,
            examine_demo:[]
          }
          if(!item.fileForm||item.fileForm.length==0||!item.fileForm[0].url){
              this.$toast.fail('Please upload the sample image first');
              return;
          }
          form.examine_demo.push(item.fileForm[0].url);
           this.$toast.loading({
            duration:0,
            message: 'Submitting...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            post("api/task/taskOrderSubmit",form).then(res=>{
                this.$toast.clear();
                this.$toast(res.code_dec);
                setTimeout(()=>{
                   this.getTaskInfo();
                },1000)
                
            })
        },
        onClickLeft:function(){
this.$router.back(-1) ;
        },
   
        init:async function(){
             this.$toast.loading({
                duration:0,
                message: 'loading...',
                forbidClick: true,
                loadingType: 'spinner' 
            });
             var userInfo=await getUserInfo();
              console.log("获取到用户信息",userInfo);
            if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"taskdetail"} 
                });
                this.$toast.clear();
                return;
            }
            this.$toast.clear();
            this.getTaskInfo();
        },
        getTaskInfo:function(){
             this.$toast.loading({
                duration:0,
                message: 'loading...',
                forbidClick: true,
                loadingType: 'spinner' 
            });
            post("/api/task/getTaskinfo",{id:this.$route.query.id,order_id:this.order_id}).then(res=>{
              if(res.code==1){
                  this.info=res.info; 
                  if(!res.userTask){
 this.userTask=Object.assign({},{},{fileForm:[]});
                  }else{
 this.userTask=Object.assign({},res.userTask,{fileForm:[]});
                  }
                 
              }else{
                  this.$toast(res.code_dec);
              }
               this.$toast.clear();
            })
        }
    }
}
</script>

