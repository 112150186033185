<template>
    <div class="taskhall">
        <div class="task_hall_header">
            <van-nav-bar  left-arrow     @click-left="onClickLeft">
                <template #title >
                    <div class="title">Task Hall</div>
                </template>
                <template #right>
                    <div class="levelwrap" @click="showLevelEdit">
                        {{current_task_level}}
                        
                    </div>
                </template>
            </van-nav-bar>
        </div>
        <div class="task_hall_content">
            <van-list
                :finished="finished"
                v-model="loading" 
                finished-text="no more"
                @load="onLoad"
                >
   <div class="taskItem" v-for="item in taskList" :key="item.id">
                <div class="taskInfo" @click="goDetail(item)">
                    <div>
                        Demand side:{{item.username}}
                    </div>
                    <div>
                        <div>
                            <div>Remaining</div>
                            <div>PAY(KSH)</div>
                        </div>
                        <div>
                            <div>{{item.surplus_number}}</div>
                            <div class="reward_price">{{item.reward_price}}</div>
                        </div>
                    </div>
                </div>
                <div class="receiveBtn">
                    <a @click.stop="receive(item)">
                        Receiving
                    </a>
                </div>
            </div>
</van-list>
          
        </div>

        <van-field
  readonly
  clickable
  label="Level"
  :value="value"
  placeholder="Level"
  @click="showPicker = true"
/>
<van-popup v-model="showLevel" round position="bottom">
  <van-picker
    show-toolbar
    
    :columns="levelColumns"
    @cancel="showLevel = false"
    @confirm="onConfirm"
  />
</van-popup>
    </div>
</template>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
export default {
    data(){
        return{
           levelColumns:[],
           current_task_level:'',
           current_task_level_id:'',
           showLevel:false,
           levelMap:{},
           value:'',
           loading:false,
           page:1,
           finished:false,
           isFirst:true,
           taskList:[],
           menu:1
        }
    },
    methods:{
            goDetail:function(item){
            this.$router.push({
              path: "/taskdetail",
              query:{id:item.task_id,order_id:item.order_id}  
        });
        },
        receive:function(item){
            this.$toast.loading({
            duration:0,
            message: 'receiving...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
           post("api/task/receiveTask",{id:item.task_id}).then(res=>{
               console.log(res);
               this.$toast.clear();
               if(res.code==1){
                   this.$toast(res.code_dec);
                    this.taskList=[];
                    this.page=1;
                    this.getTaskList();
               }else{
                  this.$toast(res.code_dec); 
               }
           }).catch(e=>{
               setTimeout(()=>{
                   this.$toast.clear();
               },1500)
               
           })
        },
        getTaskList:function(){
            this.loading=true;
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
           post("api/task/getTaskList",{group_id:'11',task_level:this.current_task_level_id,page_no:this.page}).then(res=>{
               console.log(res);
               this.loading=false;
              
               this.isFirst=false;
               if(res.info&&res.info.length>0){
                    res.info.forEach(element => {
                        this.taskList.push(element);
                    });
               }else{
                  this.finished=true;
               }
               this.$toast.clear();

              // this.taskList=res.info;
           }).catch(err=>{
               this.$toast.clear();
           })
        },
        onLoad:function(){
            if(!this.isFirst){
                console.log("do load");
                
                this.page++;
                
                this.getTaskList();
            }

         
        },
        showLevelEdit:function(){
           this.showLevel=true;
        },
        onConfirm:function(value){
          var levelinfo=this.levelMap[value];
          this.current_task_level=levelinfo.name;
          this.current_task_level_id=levelinfo.grade;
          this.showLevel=false;
          this.page=1;
          this.taskList=[];
           this.getTaskList();
        },
        onClickLeft:function(){
            this.$router.back(-1)
        },
        init:async function(){
           this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
           var res=await getBackData();
            this.levels=res.info.UserGradeList;
             if(this.levels.length>0){
              this.current_task_level=this.levels[0].name;
              this.current_task_level_id=this.levels[0].grade;
            }
            
            this.levels=this.levels.sort(function(a,b){
               return a.grade-b.grade;
            })

            for(var i=0;i<this.levels.length;i++){
               this.levelColumns.push(this.levels[i].name);
               this.levelMap[this.levels[i].name]=this.levels[i];
            }
            var userInfo=await getUserInfo();
            if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"taskhall"} 
                });
                this.$toast.clear();
                return;
            }
          this.current_task_level=userInfo.useridentity;
          this.current_task_level_id=userInfo.vip_level;

           this.$toast.clear();

           this.getTaskList();
        }
    },
    created:function(){
           
         
         
    },
    activated(){
        this.page=1;
        this.taskList=[];
this.init();
           this.menu=this.$route.query.menu;
    },
    mounted(){
       
      //  this.getTaskList();
      console.log( this.$route.path);
       
    }
}
</script>
<style scoped>
.reward_price{
    color:#F9034F;
}
.taskhall{ 
    width:100%;
    background: #F4F5F7;
    display:flex;
    flex-direction: column;
}
.taskItem{
    margin:14px;
    padding:14px;
    background: #ffffff;
    border-radius: 10px;
    height:80px;
    display: flex;
    flex-direction: row;
}
.taskInfo>div:nth-child(1){
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: flex-start;
}
.taskInfo>div:nth-child(2){
   flex-direction: column;
   flex:1;
}

.taskInfo>div>div:nth-child(1){
    display: flex;
    flex-direction: row; 
    font-size: 14px;
    color:#666666;
    margin-top:14px;
   
}
.taskInfo>div>div>div{
  display: flex;
   flex-basis: 50%;
}
.taskInfo>div>div:nth-child(2){
    display: flex;
    flex-direction: row; 
       color:#666666;
    margin-top:14px;
    font-size: 14px;
   
}
.taskItem .taskInfo{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
.taskItem .receiveBtn{
    width:76px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.taskItem .receiveBtn>a{
    width:76px;
    background:#F80050;
    height:24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#ffffff;
    border-radius: 5px;
    font-size: 12px;
}
.task_hall_header .title{
    font-weight: bold;
    color:#333333;
}
.levelwrap{
    color:#333333;
}
.levelwrap::after{
    position: absolute;
    top: 50%;
    right: 0px;
    margin-top: -5px;
    border: 3px solid;
    border-color: transparent transparent #333333 #333333;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0.8;
    content: '';
}
.task_hall_header{
    height:50px;
}
.task_hall_content{
    flex-grow: 1;
    background: #F4F5F7;
    padding-bottom:50px;
}

</style>
<style>
.taskhall .van-nav-bar__right{
    right:15px !important;
}
</style>

