<template>
    <div class="task">
       <van-tabs v-model="active">
        <van-tab title="Doing">
            <van-list
  v-model="listOptions[0].loading"
  :finished="listOptions[0].finished"
  :immediate-check="false"
  ref="doing"
  finished-text="No more"
  @load="onLoad"
>

              <div class="receive_item receive_item_doing" v-for="item in taskLists[0]" :key="item.task_id" @click="goDetail(item)">
                <div class="info">
                    <div class="title">
                        {{item.title}}
                    </div>
                    <div class="title_descri">

                      {{item.content}}
                    </div>
                    <div class="otherInfo">
                        <div>
                            <div>Start</div>
                            <div>{{item.add_time}}</div>
                        </div>
                        <div>
                            <div>Deadline</div>
                            <div>{{item.end_time}}</div>

                        </div>
                        <div>
                             <div>Unit Price</div>
                            <div class="unitprice">KSH{{item.reward_price}}</div>
                        </div>

                    </div>
                    <div class="fnlist">
                        <div @click.stop="openUrl(item.link_info)">Open the link</div>
                        <div @click.stop="copyText(item.link_info)">Copy the link</div>
                    </div>
                </div>
                
                 <div class="submit">
                    <div @click.stop="emptyfn">
<van-uploader preview-size="40px" v-model="item.fileForm" :after-read="afterRead" />

                    </div>
                    <div>
                       <a @click.stop="submit(item)">Submit</a>

                    </div>
                 </div>
              </div>

 
</van-list>

        </van-tab>
        <van-tab title="Inspect">
            <van-list
  v-model="listOptions[1].loading"
  :finished="listOptions[1].finished"
  finished-text="No more"
  @load="onLoad"
>
              <div class="receive_item receive_item_checking" v-for="item in taskLists[1]" :key="item.task_id">
                <div class="info">
                    <div class="title">
                        {{item.title}}
                    </div>
                    <div class="title_descri">

                      {{item.content}}
                    </div>
                    <div class="otherInfo">
                        <div>
                            <div>Submission time</div>
                            <div>{{item.trial_time}}</div>
                        </div>
                        <div>
                            <div>Audit period</div>
                            <div>1~2days</div>

                        </div>
                         

                    </div>
                  
                </div>
                

              </div>
            </van-list>
        </van-tab>
        <van-tab title="Complete">
                        <van-list
  v-model="listOptions[2].loading"
  :finished="listOptions[2].finished"
  finished-text="No more"
  @load="onLoad"
>
<div class="receive_item receive_item_checking" v-for="item in taskLists[2]" :key="item.task_id">
                <div class="info">
                    <div class="title">
                       {{item.title}}
                    </div>
                    <div class="title_descri">

                      {{item.content}}
                    </div>
                    <div class="otherInfo">
                        <div>
                            <div>Start</div>
                            <div> {{item.add_time}}</div>
                        </div>
                        <div>
                            <div>Complete</div>
                            <div>{{item.handle_time}}</div>

                        </div>
                         <div>
                            <div>Unit Price</div>
                            <div class="unitprice">KSH{{item.reward_price}}</div>

                        </div>

                    </div>
                  
                </div>
                

              </div>
                        </van-list>
        </van-tab>
        <van-tab title="Fail">
                        <van-list
  v-model="listOptions[3].loading"
  :finished="listOptions[3].finished"
  finished-text="No more"
  @load="onLoad"
>
       <div class="receive_item receive_item_checking" v-for="item in taskLists[3]" :key="item.task_id">
                <div class="info">
                    <div class="title">
                        {{item.title}}
                    </div>
                    <div class="title_descri">

                       {{item.content}}
                    </div>
                    <div class="otherInfo">
                        <div>
                            <div>Submission time</div>
                            <div>{{item.add_time}}</div>
                        </div>
                        <div>
                            <div>Failure reason</div>
                            <div>expire/Audit failed</div>

                        </div>
                         

                    </div>
                  
                </div>
                

              </div>
                        </van-list>
        </van-tab>
        </van-tabs>
    </div>
</template>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
 import axios from 'axios'
export default {
    data(){
        return {
             active:0,
             loading:false,
             finished:false,
             page:0,
             taskLists:{
                 0:[],
                 1:[],
                 2:[],
                 3:[]
             },
             listOptions:{
                 0:{
                     loading:false,
                     finished:false,
                     page:0,
                     status:1
                 },
                 1:{
                     loading:false,
                     finished:false,
                     page:0,
                     status:2
                 },
                 2:{
                     loading:false,
                     finished:false,
                     page:0,
                     status:3
                 },
                 3:{
                     loading:false,
                     finished:false,
                     page:0,
                     status:4
                 },
                 4:{
                     loading:false,
                     finished:false,
                     page:0
                 }
             },
             isFirst:true,
              token:this.$store.getters.token,
        }
    },
    created(){
        if(this.$route.query.index){
            this.$store.dispatch("setMenuActive",parseInt(this.$route.query.index));
        }
        
    },
    activated:function(){
        for(var i in this.listOptions){
            this.listOptions[i].page=0;
        }
            this.init();
             this.$store.dispatch("setShowNav",true);
              if(this.$route.query.index){
             this.$store.dispatch("setMenuActive",parseInt(this.$route.query.index));
           }
    },
    methods:{
           receive:function(item){
            this.$toast.loading({
            duration:0,
            message: 'receiving...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
           post("api/task/receiveTask",{id:item.task_id}).then(res=>{
               console.log(res);
               this.$toast.clear();
               if(res.code==1){
                   this.$toast(res.code_dec);
               }else{
                  this.$toast(res.code_dec); 
               }
           }).catch(e=>{
               setTimeout(()=>{
                   this.$toast.clear();
               },1500)
               
           })
        },
        goDetail:function(item){
            this.$router.push({
              path: "/taskdetail",
              query:{id:item.task_id,order_id:item.order_id}  
        });
        },
        emptyfn:function(){

        },
        openUrl:function(url){
           window.open(url,"_blank");
        },
        submit:function(item){
          console.log(item);
          var form={
            order_id:item.order_id,
            examine_demo:[]
          }
          if(!item.fileForm||item.fileForm.length==0||!item.fileForm[0].url){
              this.$toast.fail('Please upload the sample image first');
              return;
          }
          form.examine_demo.push(item.fileForm[0].url);
           this.$toast.loading({
            duration:0,
            message: 'Submitting...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            post("api/task/taskOrderSubmit",form).then(res=>{
                this.$toast.clear();
                this.$toast(res.code_dec);
                setTimeout(()=>{
                   this.refresh();
                },1000)
                
            })
        },
        refresh:function(){
          //刷新当前页面
          this.taskLists[this.active]=[];
          this.listOptions[this.active].page=0;
          this.listOptions[this.active].loading=true;
          this.listOptions[this.active].finished=false;
          this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            this.onLoad(); 
        },
         copyText:function(text){
            var input = document.createElement("textarea");   // 直接构建input
                input.value = text;  // 设置内容
                document.body.appendChild(input);    // 添加临时实例
                input.select();   // 选择实例内容
                document.execCommand("Copy");   // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$toast(" copy success");
        },
        afterRead(file){
            console.log(file);
             file.status = 'uploading';
            file.message = 'uploading';
             var image = new FormData()
             //api/User/UploadImg
              
             image.append('image', file.file);
             image.append('type', 3);
             image.append("token",this.token);
             var url=process.env.VUE_APP_BASE_API+"/api/User/UploadImg";
             console.log("url:",url);
             axios.post(url,image,{
            headers: {
              "Content-Type": "multipart/form-data",
              "X-Mx-ReqToken":this.token
            }
            }).then(res=>{
                console.log("res",res);
                if(res.data&&res.data.code==1){
              //  this.taskForm.examine_demo.push(res.data.url);
              file.url=res.data.url;
                 file.status = 'success';
                file.message = 'success';
                }else{
                     file.status = 'failed';
                file.message = 'failed';
                }
                
            }).catch(e=>{
                 file.status = 'failed';
                file.message = 'failed';
            })
       },
       onLoad:function(){
           if(!this.isFirst){
               return;
           }
           console.log("查询下一页");
           this.getTaskList();
       },
       getTaskList:function(tactive){
           var active=tactive?tactive:this.active;
           this.listOptions[active].page++;
          post("api/task/taskOrderlist",{status:this.listOptions[active].status,page_no:this.listOptions[active].page,is_u:2}).then(res=>{
              console.log(res);
              if(res.info){
                for(var i=0;i<res.info.length;i++){
                    var temp= Object.assign({},res.info[i], {
                       fileForm:[]
                    })

                    this.taskLists[active].push(temp);
                }
              }else{
                  console.log("设置结束",active);
                   this.listOptions[active].finished=true;
              }
               this.listOptions[active].loading=false;
              this.$toast.clear();

              this.isFirst=true;
          })
       },
       init:async function(){
           this.taskLists[0]=[];
           this.taskLists[1]=[];
           this.taskLists[2]=[];
           this.taskLists[3]=[];
         this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
              var userInfo=await getUserInfo();
              console.log("获取到用户信息",userInfo);
            if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"task"} 
                });
                this.$toast.clear();
                return;
            }
           
             this.getTaskList(0);
             console.log("检查滚动");
            
            //  this.getTaskList(1);
            //  this.getTaskList(2);
            //  this.getTaskList(3);
       }
    },
    mounted(){
    //    setInterval(()=>{
    //        console.log("检查",this,this.$refs.doing.check);
    //    },10000)
    }
}
</script>
<style scope>
.task{ 
    width:100%;
    background: #F4F5F7;
}
.unitprice{
    color:#F9034F !important;
}
.receive_item{
    margin:12px;
    border-radius: 10px;
    padding:12px 12px 0px 12px;
    background: #ffffff;
    font-size:14px;
    overflow: hidden;
    height:203px;
    display:flex;
    flex-direction: column;
}

.receive_item_checking{
   
    height:120px; 
}
.receive_item .info>div{
  flex-grow: 1;
    display: flex;
    width: 100%;
}
.receive_item .info{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    padding-bottom:20px;
}
.receive_item .submit{
    height:60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.receive_item .submit a{
    display: flex;
    align-items: center;
    justify-content: center;
    background:#F80050;
    color:#ffffff;
    width:76px;
    border-radius: 3px;
    height:28px; 
}
 
.task .van-tabs{
    height:100%;
}
.otherInfo{
    display:flex;
    flex-direction:row; 
    margin-top: 14px;
}
.otherInfo>div{
    flex-grow: 1;
}
.otherInfo>div>div:nth-child(1){
  color:#666666;
  font-weight: 500;
}
.otherInfo>div>div:nth-child(2){
  color:#333333;
  margin-top: 6px;
  font-weight: 500;
}

.otherInfo>div>div{
    display: flex;
    justify-content: flex-start;
    text-align: left;
}
.task .title{
   font-size:16px;
   color:#333333;
   font-weight:bold;
   justify-content: flex-start;
   text-align: left;
}
.task .title_descri{
   font-size:14px;
   color:#999999;
   justify-content: flex-start;
   text-align: left;
   margin-top: 8px;
}
.fnlist{
    display: flex;
    flex-direction: row;
    margin-top:16px;
}
.fnlist>div{
    color:#00D5CD;
}
.fnlist>div:nth-child(1){
    padding-right:20px;
    border-right:1px solid #00D5CD;
}
.fnlist>div:nth-child(2){
    padding-right:20px;
     padding-left:20px;
}
.receive_item_doing .info{
    border-bottom:1px solid #E5E5E5;
}
.task{
    padding-bottom:50px;
    overflow-y: auto;
}
</style>
<style >
.task .van-tab--active>span{
    color:#EC054C;
}
</style>
