<template>

<div class="login">
      <div class="person_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title"> </div>
                </template>
                
            </van-nav-bar>
        </div>
        <div>
              <div class="login_word">

               Welcome to register
              </div>
              <div class="login_panel">
                        
                <div class="input_item">
                   
                     <div>
                       <a>+254</a>
                       <input class="input inputspec" v-model="username" placeholder="phone number,Same 789123456"/>
                       </div>   
                             
                </div>
                 <div class="input_item">
                     <div>
                      
                       <input class="input" placeholder="Please enter the verification code" v-model="code"/>
                       <div class="vendor">
                         <img @click="changeCode" :src="basePath+'/api/Account/code?code_rand='+code_rand"/>
                       </div>
                       </div>   
                             
                </div>
                <div class="input_item">
                     <div>
                      
                       <input class="input" type="password" placeholder="Please enter the login password" v-model="password"/>
                       </div>   
                             
                </div>
                            <div class="input_item">
                     <div>
                      
                       <input class="input" type="password" placeholder="Please confirm your password" v-model="re_password"/>
                       </div>   
                             
                </div>
                            <div class="input_item">
                     <div>
                      
                       <input class="input" placeholder="Please enter the invitation code" :disabled="canedit" v-model="recommend"/>
                       </div>   
                             
                </div>
                             
                

                <div class="loginbtn">
                       <a @click="confirmRegiste">Register now</a>
                </div>

                <div class="tip">
                  <div>
                 <a>Have an account?  </a>
                 <a class="r" @click="goLogin">Login</a>
                  </div>
                     <div>
                 <a>Have an account?  </a>
                 <a class="r">Download</a>
                  </div>
                </div>

              </div>

        </div>

</div>
 
</template>
<style scoped>
.vendor img{
  width:100%;
}
input{
  font-weight: normal;
}
.vendor{
  width: 100px;
  height:28px;
  background: #cccccc;
}
.tip{
  margin-top:10px;
  display: flex;
  flex-direction: row;
  color:#999999;
}
.r{
  color:#333333;
}
.tip>div:nth-child(1){
  margin-right:20px;
}
.tip>a:nth-child(1){
  color:#999999;
}
.tip>a:nth-child(2){
  color:#333333;
}
.loginbtn{
  margin-top:20px;
}
.loginbtn>a{

  height:44px;
  width:152px;
  color:#ffffff;
  background: #F9034F;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:18px;
  font-weight: bold;
}
.rember{
  display:flex;
  flex-direction: row;
  align-items: end; 
  margin-top:-10px;
}
.input_item{
  border-bottom: 1px solid #E5E5E5;
  padding-bottom:10px;
  margin-bottom: 20px;
}
.inputspec{
 margin-left:20px;
}
.input{
  flex-grow: 1;
  border:1px solid transparent; 

}
.input_item>div:nth-child(1){
  font-size:15px;
  font-weight:bold;
}
.input_item>div:nth-child(1){
   margin-top:5px;
   display: flex;
   flex-direction: row;
}
.input_item>div:nth-child(1)>a{
  width:50px;
  background: #ffffff;
  padding:5px;
  display: flex;
  color:#333333;
  border-radius: 2px;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;

}
.input_item>div:nth-child(1)>a::after{
   position: absolute !important;
    top: 50%;
    right: 5px;
    margin-top: -5px;
    border: 3px solid;
    border-color: transparent transparent #333333 #333333;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0.8;
    content: '';
    position: relative;
}
.login_panel{
  margin:24px;
}
.person_header .title{
    font-weight: bold;
    color:#333333;
}
.person_header{
    height:50px;
}
.login_word{
  margin-left:24px;
   margin-top:25px;
   font-size:24px;
   color:#333333;

}
.login_word img{
  width:137px;
}
.login{
  font-size:12px;
}
</style>
<script>
import {fetch} from '@/util/api'
export default {
    data(){
        return {
            username: '', 
            password: '',
            re_password:'',
            dest:'86',
            lang:'en',
            recommend:'',
            code_rand:new Date().getTime(),
            basePath:process.env.VUE_APP_BASE_API,
            code:'',
            src:'',
            canedit:false
        }
    },
    methods:{
      goLogin:function(){
 this.$router.push({
                    path: "/login",
                });
      },
      onClickLeft:function(){
              this.$router.back(-1) 
      },
      changeCode:function(){
         this.code_rand=new Date().getTime();
      },
      confirmRegiste:function(){
         if(!this.username){
          this.$toast("Please enter your mobile phone number");
          return;
        }
        var msg="Confirm to use "+"254"+this.username+" as your withdrawal account, whether to continue";
          this.$dialog.confirm({
            title: 'Warm prompt',
            message: msg,
          })
            .then(() => {
              // on confirm
              this.onSubmit();
            })
            .catch(() => {
              // on cancel
               console.log("cancel ");
            });
      },
      autoLogin:function(){
        //注册成功后自动登录
           this.$toast.loading({
            duration:0,
            message: 'waiting...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
             fetch("api/User/Login",{username:this.username,password:this.password,lang:'en'}).then(res=>{
            console.log("res",res);
            this.$store.dispatch('setToken',res.info);
            console.log("getter",this.$store.getters.userInfo);
            console.log("登录成功，回跳地址:"+this.src);
            this.$toast.clear();
            if(!this.src){
             this.$router.push({
                    path: "/",
                    query:{
                      index:0
                    }
                });
            }else{
             this.$router.push({
                    path: "/"+this.src,
                });
            }

        }).catch(err=>{
           this.$toast.clear();
        })
      },
      onSubmit(values) {

        if(this.username){
          this.username=this.username.trim();
        }
        if(!this.username){
          this.$toast("Please enter your mobile phone number");
          return;
        }
        if(this.username.length!=9){
                    this.$toast("Phone format error");
          return;
        }
         if(isNaN(this.username)){
                         this.$toast("Phone format error");
              return;
         }
        if(!this.password){
          this.$toast("Please input a password");
          return;
        }
        if(!this.re_password){
          this.$toast("Please enter the password again");
          return;
        }
        if(!this.code){
          this.$toast("Please enter the picture verification code");
          return;
        }
          this.$toast.loading({
            duration:0,
            message: 'waiting...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
      console.log('submit', values);
        fetch("api/User/register",{username:this.username,password:this.password,re_password:this.re_password,lang:'en',recommend:this.recommend,code_rand:this.code_rand,code:this.code}).then(res=>{
            console.log("res",res);
             this.$toast.clear();
            if(res.code==1){
              this.$toast(res.code_dec);
               setTimeout(() => {
                 this.autoLogin();
               }, 1000);
            }else{
              this.$toast(res.code_dec);
            }
            
           
        }).catch(err=>{
          
          this.$toast.clear();
          this.$toast("register failed");
        })
      },
       
    },
      activated(){
 this.$store.dispatch("setShowNav",false);
  },
    
    mounted(){
        console.log("params",this.$route.query);
        this.recommend=this.$route.query.id_code;
        if(this.recommend){
           this.canedit=true;
        }else{
           this.canedit=false;
        }
      
      console.log("getter",this.$store.getters.userInfo);
    }
}
</script>

