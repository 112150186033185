<template>
    <div class="withdraw">
               <div class="withdraw_header">
                    <van-nav-bar    left-arrow  @click-left="onClickLeft">
                        <template #title>
                            <div class="title">WITHDRAWAL</div>
                        </template>
                        
                    </van-nav-bar>
            </div>
            <div class="withdrawform">
              <div class="define_money">
                  <input v-model="money" placeholder="Enter withdrawal amount">
              </div>
             
              <div class="submitBtn">
                  <a class="" @click="confirmDraw">
                      Confirm withdrawal
                  </a>
              </div>
            </div>

        <van-popup v-model="showpwd1" position="bottom" :style="{ height: '20%',background:'#F4F5F7',paddingTop:'30px' }" >
            <div class="define_money">
                  <input v-model="password" placeholder="Enter your fund password">
              </div>
              <div class="submitBtn confirmpwd">
                  <a class="" @click="dodraw">
                      Confirm
                  </a>
              </div>
        </van-popup>
          <f-dialog :vshow="showpwd" :title="title" :vmsg="msg" @ok="dodraw" @cancel="cancel"></f-dialog>
    </div>
</template>
<style scoped>
.confirmpwd>a{
    width:80% !important;
}
.submitBtn{
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top:20px;
}
.submitBtn>a{
   height:50px;
   width:224px;
   color:#ffffff;
   background:#F9034F;
   border-radius: 5px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 18px;
}
.withdrawform{
    margin-top:10px;
}
.define_money{
    background: #ffffff;
    border-radius: 10px;
    margin:0px 12px;
    display: flex;
    height:30px;
    align-items: stretch; 
    padding:10px;
    margin-bottom: 10px;

}
.define_money input{
    border:1px solid transparent;
    flex-grow: 1;
    font-size: 15px;
    
}
.withdraw_header{
    height:50px;
}
  .withdraw{
      background: #F4F5F7;
      width:100%;
      font-size:12px;
  }
</style>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
   import fdialog from './dialog' 
export default {
    components:{fDialog:fdialog},
   data(){
       return {
         money:'',
         account:'',
         showpwd:false,
         password:'',
         userInfo:{},
         showpwd1:false,
         title:'',
         msg:''
       }
   },
   methods:{
       cancel:function(){
               this.showpwd=false;
       },
       onClickLeft:function(){
 this.$router.back(-1) ;
       },
       clearform:function(){
           this.password="";
           this.account="";
           this.money="";
       },
       init:async function(){
        this.$toast.loading({
                    duration:0,
                    message: 'loading...',
                    forbidClick: true,
                    loadingType: 'spinner' 
                    });
          var userInfo=await getUserInfo();
          if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"withdraw"} 
                });
                this.$toast.clear();
                return;
            }
          this.userInfo=userInfo;
          console.log("person 获取用户信息",this.userInfo);
           this.$toast.clear();
       },
       confirmDraw:function(){
         //确认提款，输入资金密码
           if(!this.money){
              this.$toast("Please enter the withdrawal amount");
              return;
          }
          if(isNaN(this.money)){
                this.$toast("Abnormal amount format");
              return;
          }
          this.account="254"+this.userInfo.phone;
          this.msg="Warm prompt";
         this.msg="Confirm whether to withdraw "+this.money+"KSH to "+this.account;
         
         this.showpwd=true;
       },
       dodraw:function(){
            this.showpwd=false;
          if(!this.money){
              this.$toast("Please enter the withdrawal amount");
              return;
          }
          if(!this.account){
              this.$toast("Please enter your withdrawal account number");
              return;
          }

          
          this.showpwd=false;
        this.$toast.loading({
                    duration:0,
                    message: '',
                    forbidClick: true,
                    loadingType: 'spinner' 
                    });
          post("/api/Transaction/draw",{draw_money:this.money,account:this.account,drawword:this.password,draw_type:'bank',user_bank_id:1}).then(res=>{
             this.$toast.clear();
             if(res.code==1){
                 this.$toast(res.code_dec);
                 this.clearform();
             }else{
                 this.$toast(res.code_dec);
             }
          })
       }
   },
   activated(){
 this.init();
  this.clearform();
   },
   mounted(){
     
   }   
}
</script>
