<template>
    <div class="business_hall">
         <div class="business_hall_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Business Hall</div>
                </template>
                <template #right>
                    <div class="submit" @click="doSubmit" >
                        Submit
                        
                    </div>
                </template>
            </van-nav-bar>
        </div>
        <div class="taskInfo"  style="padding-bottom:50px;">
            <div class="task_item">
                  <van-form validate-first>
                      <van-field
                        v-model="taskForm.title"
                        label="Task title"
                        placeholder="Please enter a task title"
                        input-align="right"
                        />
                         <van-field
                        v-model="taskForm.content"
                        label="Task Brief"
                        placeholder="Please enter a task description"
                        input-align="right"
                        />
                        <van-field
                        v-model="taskForm.reward_price"
                        label="Task unit price"
                        type="number" 
                        placeholder="0KSH"
                        input-align="right"
                        @input="unitChange"
                        />
                        <van-field
                        v-model="taskForm.total_number"
                        type="digit"
                        @input="totalNumChange"
                        label="Number received"
                        placeholder="Please enter the number"
                        input-align="right"
                        />
                        <van-field
                        type="digit"
                        v-model="taskForm.person_time"
                        label="Number of receipts"
                        placeholder="0 Times/person"
                        input-align="right"
                        />
                       <van-field
                        v-model="taskForm.total_price"
                        readonly
                        label="Total task price"
                        placeholder="0 KSH"
                        input-align="right"
                        />
                        
                  </van-form>

            </div>

                        <div class="task_item">
                  <van-form validate-first>
                      <van-field
                        v-model="taskForm.link_info"
                        label="Link information"
                        placeholder="Please enter the link address"
                        input-align="right"
                        />
                         <van-field class="taskLevelSelect"  @click="clickSetLevel"
                        v-model="taskForm.task_level"
                        label="Task level"
                        
                        input-align="right"
                        />
                        <van-field 
                        v-model="taskForm.end_time"
                        label="Deadline"
                        @click="selectDate"
                        readonly 
                        placeholder="click to select date"
                        input-align="right"
                        />
                      
                        
                  </van-form>

            </div>
           <div class="task_item" style=" padding:14px 14px;">
               <div class="task_item_title">
                   Conditions of completion
               </div>
               <div class="conditions">
                  <a v-for="item in authenticationList" :class="{active:item.active}" :key="item.id" @click="selectConditions(item.id)">
                    {{item.name}}
                  </a>
                
               </div>
            </div>

          

            <div class="task_item" style=" padding:14px 14px;">
               <div class="task_item_title">
                   Upload requirements
               </div>
                <div class="upload">
                    <van-uploader v-model="fileList" :after-read="afterRead" />
                </div>
            </div>

            <div class="task_item"    @click="goStepSet" >
                <van-field disabled
                 input-align="right"
                    v-model="taskForm.task_step.length"
                    label="Sample audit"
                    :is-link="true"  
                    />
            </div>
            <div class="tasktips">
                Platform handling fee 0.001 This release will require payment of about 0,Please ensure that the balance of the account is sufficient Tip: This platform prohibits the release of pornography, gambling, drugs and all other legal prohibited content, such as deduction points, blocked account.
            </div>
            <div class="fixed_fn">
<van-datetime-picker
  v-model="currentDate"
  v-if="showDate"
  type="date"
  title="Please select a date"
  :min-date="minDate"
  :max-date="maxDate"
  @confirm="sureDate"
  @cancel="closePicker"
/>

            </div>

        </div>
        <van-popup v-model="showStep" position="right" :style="{ height: '100%',width: '100%' }" >
           <BusinessHallStep :steps="taskForm.steps" @confirm="confirmStep" @cancel="cancelStep"></BusinessHallStep>
        </van-popup>
        <van-popup v-model="showLevels" round position="bottom">
  <van-picker
    show-toolbar
    :columns="columns"
    @cancel="showLevels = false"
    @confirm="onConfirm"
  />
</van-popup>
 
    </div>
</template>
<style>
.business_hall .van-cell{
    padding:10px 2px
}
.business_hall .van-field__label{
    width:auto;
}
.fixed_fn{
    position:fixed;
    bottom:0px;
    width:100%;
    z-index:11111;
}
.taskLevelSelect .van-field__body input{
    padding-right:10px;
}
.taskLevelSelect .van-field__body::after{
     position: absolute;
    top: 50%;
    right: 0px;
    margin-top: -5px;
    border: 3px solid;
    border-color: transparent transparent #333333 #333333;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0.8;
    content: '';
}
</style>
<script>
import {dateFormat} from '@/util/dateUtil'
import {getBackData} from '@/util/api'
import BusinessHallStep from './BusinessHallStep'
import {post} from '@/util/api' 
import axios from 'axios' 
export default {
  components: {
    BusinessHallStep
  },
    data(){
        return{
          value2:'',
          taskForm:{
            task_class:'11',
            title:'',
            content:'',
            reward_price:'',
            total_number:'',
            person_time:'',
            total_price:'',
            link_info:'',
            task_level:'',
            task_level_id:'',
            end_time:'',
            finish_condition:[],
            task_step:[],
            lang:'en',
            examine_demo:[],
            steps:[],
            link_info:''
          },
          fileList:[],
          minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          maxDate: new Date(new Date().getFullYear(), new Date().getMonth()+2, 30),
          currentDate: new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate()),
          showDate:false,
          userInfo:this.$store.getters.userInfo,
          token:this.$store.getters.token,
          levels:[],
          authenticationList:[],
          finish_condition:{},
          showStep:false,
          showLevels:false,
          columns:[],
          levelMap:{}
        }

    },
    methods:{
       formClear:function(){
         this.fileList=[];
         for(var i in this.taskForm){
           if(i=='task_class'){
             continue;
           }else{
             if(i=="finish_condition"){
               this.taskForm['finish_condition']=[];
             }else if(i=="examine_demo"){
                this.taskForm['examine_demo']=[];
             }else if(i=="task_step"){
                this.taskForm['task_step']=[];
             }else if(i=="steps"){
this.taskForm['steps']=[];
             }else{
               this.taskForm[i]="";
             }
           } 
         }
       },
         onClickLeft:function(){
            this.$router.back(-1)
        },
        doSubmit:function(){
            this.taskForm['token']=this.token;
          if(!this.taskForm.title){
            this.$toast("title cannot be empty");
            return;
          }
          if(!this.taskForm.content){
            this.$toast("Task Brief cannot be empty");
            return;
          }
          if(!this.taskForm.reward_price){
            this.$toast("Task unit price cannot be empty");
            return;
          }
         if(!this.taskForm.total_number){
            this.$toast("Number received cannot be empty");
            return;
          }
          if(!this.taskForm.person_time){
            this.$toast("Number of receipts cannot be empty");
            return;
          }
          if(!this.taskForm.link_info){
            this.$toast("Link information cannot be empty");
            return;
          }
          if(!this.taskForm.task_level){
            this.$toast("task_level cannot be empty");
            return;
          }
          if(!this.taskForm.end_time){
            this.$toast("Deadline cannot be empty");
            return;
          }
          
          
  this.$toast.loading({
            duration:0,
            message: 'submiting...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
          post("api/task/publishTask",this.taskForm).then(res=>{
             this.$toast.clear();
             if(res.code==1){
                 this.$toast( res.code_dec);
                 setTimeout(()=>{
                    this.$router.back(-1)
                 },1000)
             }else{
                 this.$toast( res.code_dec);
             }
          })
        },
        unitChange:function(value,a,b){
          if(this.taskForm.total_number){
              var commission=this.levelMap[this.taskForm.task_level].pump;
                var pump=this.levelMap[this.taskForm.task_level].pump;
              this.taskForm.total_price=this.taskForm.total_number*value;
              this.taskForm.total_price=this.taskForm.total_price+this.taskForm.total_price*(pump/100);
              console.log(this.taskForm.total_price);
               this.taskForm.total_price=parseFloat(this.taskForm.total_price.toFixed(3));
          }
        },
        totalNumChange:function(value,a,b){
            console.log("1qqqqq");
          if(this.taskForm.reward_price){
               var pump=this.levelMap[this.taskForm.task_level].pump;
              this.taskForm.total_price=this.taskForm.reward_price*value;
               this.taskForm.total_price=this.taskForm.total_price+this.taskForm.total_price*pump/100;
               this.taskForm.total_price=parseFloat(this.taskForm.total_price.toFixed(3));
          }
        },
        clickSetLevel:function(){
            this.showLevels=true;
            for(var i=0;i<this.levels.length;i++){
               this.columns.push(this.levels[i].name);
               this.levelMap[this.levels[i].name,this.levels[i]];
            }
            //this.columns=[];
        },
        onConfirm:function(value){
            console.log("onConfirm",value);
          this.taskForm.task_level = value;
         // this.taskForm.commission
          this.showLevels = false;
        },
        closePicker:function(){
            this.showDate=false;
        },
        cancelStep:function(){
 this.showStep=false;
        },
        confirmStep:function(steps){
             console.log("触发确认操作",steps);
             this.showStep=false;
             for(var i=0;i<steps.length;i++){
               if(steps[i].fileList.length>0&&steps[i].fileList[0].url){
                var temp={url:steps[i].fileList[0].url,descri:steps[i].descri};
                this.taskForm.task_step.push(temp);
               }

           }
           console.log(this.taskForm.task_step);
        },
        selectConditions(values){ 
            if(!this.finish_condition[values]&&this.finish_condition[values]!="0"){
              this.finish_condition[values]=values;
              this.authenticationList[values].active=true;
            }else{
               delete this.finish_condition[values];
               this.authenticationList[values].active=false;
            }
          
        },
        sureDate(value){
          var date=dateFormat("YYYY-mm-dd",value);
          this.taskForm.end_time=date;
          this.showDate=false;
        },
        selectDate(){
          this.showDate=true;
        },
        validator(val) {
            console.log("晓燕");
            return /1\d{10}/.test(val);
        },
        afterRead(file) {
            file.status = 'uploading';
            file.message = 'upload...';
             var image = new FormData()
             //api/User/UploadImg
              
             image.append('image', file.file);
             image.append('type', 3);
             image.append("token",this.token);
             var url=process.env.VUE_APP_BASE_API+"/api/User/UploadImg";
             console.log("url:",url);
             axios.post(url,image,{
            headers: {
              "Content-Type": "multipart/form-data",
              "X-Mx-ReqToken":this.token
            }
            }).then(res=>{
                console.log("res",res);
                if(res.data&&res.data.code==1){
                this.taskForm.examine_demo.push(res.data.url);
                 file.status = 'success';
                file.message = 'success';
                }
                
            }).catch(e=>{
                 file.status = 'failed';
                file.message = 'failed';
            })
            // setTimeout(() => {
            //     file.status = 'failed';
            //     file.message = '上传失败';
            // }, 1000);
        },
        init:async function(){
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
           var res= await getBackData();
           console.log("init data",res);
            this.levels=res.info.UserGradeList;
            
            this.levels=this.levels.sort(function(a,b){
               return a.grade-b.grade;
            })
            console.log(this.levels);
            if(this.levels.length>0){
             this.taskForm.task_level=this.levels[0].name;
             this.taskForm.task_level_id=this.levels[0].grade;
            }
            this.authenticationList=[];
            for(var i=0;i<res.info.authenticationList.length;i++){
              this.authenticationList.push({id:i,name:res.info.authenticationList[i],active:false});
            }

            for(var i=0;i<this.levels.length;i++){
               this.levelMap[this.levels[i].name]=this.levels[i];
            }
          this.$toast.clear();
            ///level 排序
        },
        goStepSet:function(){
            console.log("1111111111");
            this.showStep=true;
        } 
    },
      activated(){
        this.formClear();
       this.$store.dispatch("setShowNav",false);
       this.init();
     },
    mounted(){
      // console.log(this.userInfo)
      // this.$store.dispatch("setShowNav",false);
      // this.init();
    }
}
</script>
<style scoped>
.conditions .active{
    color:#333333 !important;
    border:1px solid #333333 !important;
}
.tasktips{
    color:#999999;
    font-size:12px;
    margin-top:12px;
    padding:0px 12px;
    text-align:left;
    line-height:18px;
    background:#F4F5F7;
    padding-bottom:50px ;
}
.task_item .upload{
    display:flex;
    justify-content: flex-start;
    margin-top:10px;
}
.task_item .conditions>a{
    display:flex;
    flex-wrap:nowrap;
    white-space: nowrap;
    border: 1px solid #E5E5E5;
    padding:2px;
    margin-bottom:8px;
    margin-right:5px;
    color:#cccccc;
    font-size:0.35rem;
}
.task_item .conditions{
    display:flex;
    flex-direction: row; 
     flex-wrap:wrap;
     margin-top:10px;

}
.task_item_title{
    color:#333333;
    display:flex;
   justify-content: flex-start;
  
   font-weight:bold;
   font-size:15px;
}
.task_item{
    margin:14px;
    padding:0px 14px;
    background: #ffffff;
    border-radius: 6px; 
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
}
.taskInfo{
    background:#F4F5F7;
    overflow-y: auto;
}
.business_hall .submit{
  color:#EC054C;
  font-size: 16px;
}
.business_hall_header .title{
    font-weight: bold;
    color:#333333;
}
.business_hall_header{
    height:50px;
}
.business_hall{
    background: #F4F5F7;
    display:flex;
    flex-direction:column;
}
</style>