<template>
    <div class="team_report">
        <div class="person_header">
                <van-nav-bar    left-arrow  @click-left="onClickLeft">
                    <template #title>
                        <div class="title">Team Reports</div>
                    </template>
                    
                </van-nav-bar>
            </div>
            <div class="content">
 
          <div class="time">
               <div @click="changeStartTime('startDate')">
                 <div>Start Time</div>
                 <div>{{startDate}}</div>
               </div>
                <div class="direct">
                   <img src="@/assets/img/ic_time.png" @click="search" />
               </div>
               <div @click="changeEndTime('endDate')">
                    <div>End Time</div>
                    <div>{{endDate}}</div>
               </div> 
          </div>
          <div class="preview">
              <div>
                  <div>Team recharge(KSH)</div>
                  <div>{{reportInfo.teamRecharge}}</div>
              </div>
              <div>
                  <div>Team withdraw(KSH)</div>
                  <div>{{reportInfo.teamWithdrawal}}</div>
              </div>
              <div>
                  <div>Number of first charge(PCE)</div>
                  <div>{{reportInfo.firstRechargeToday}}</div>
              </div>
              <div>
                  <div>Number of first push(PCE)</div>
                  <div>{{reportInfo.directlyUnder}}</div>
              </div>
              <div>
                    <div>Team size(PCE)</div>
                  <div>{{reportInfo.teamNumber}}</div>
              </div>
              <div>
               <div>New team(PCE)</div>
                  <div>{{reportInfo.newReg}}</div>

              </div>


          </div>
          <div class="preview_detail">
                    <van-tabs v-model="levelactive">
                    <van-tab title="Level 1">
                               <div class="preview_detail">
                   
                        <div class="grid">
                            <div class="grid_wrap gridtitle">
                                 <div class="gridcell">
                                    User
                                 </div>
                                 <div class="gridcell">
                                     Recharge
                                 </div>
                                <div class="gridcell">
                                   Superior
                                </div>
                                <div class="gridcell">
                                    Rebate
                                 </div>
                                 <div class="gridcell">
                                    Commission
                                 </div>
                                  
                            </div> 
                             <div class="grid_wrap " v-for="item in LevelDatas[1]" :key="item.id">
                                 <div class="gridcell">
                                   {{item.username}}
                                 </div>
                                <div class="gridcell">
                                     {{item.recharge?item.recharge:0}}
                                </div>
                                <div class="gridcell">
                                     {{item.susername}}
                                 </div>
                                   <div class="gridcell">
                                    {{item.totalRebat?item.totalRebat:0}}
                                 </div>
                                   <div class="gridcell">
                                      {{item.commission?item.commission:0}}
                                 </div>
                            </div>
                                
 
                        </div>

                   

          </div>

                    </van-tab>
                    <van-tab title="Level 2">
                     <div class="preview_detail">
                   
                        <div class="grid">
                            <div class="grid_wrap gridtitle">
                                 <div class="gridcell">
                                    User
                                 </div>
                                 <div class="gridcell">
                                     Recharge
                                 </div>
                                <div class="gridcell">
                                   Superior
                                </div>
                                <div class="gridcell">
                                    Rebate
                                 </div>
                                 <div class="gridcell">
                                    Commission
                                 </div>
                                  
                            </div> 
                             <div class="grid_wrap " v-for="item in LevelDatas[2]" :key="item.id">
                                 <div class="gridcell">
                                   {{item.username}}
                                 </div>
                                <div class="gridcell">
                                     {{item.recharge?item.recharge:0}}
                                </div>
                                <div class="gridcell">
                                     {{item.susername}}
                                 </div>
                                   <div class="gridcell">
                                    {{item.totalRebat?item.totalRebat:0}}
                                 </div>
                                   <div class="gridcell">
                                      {{item.commission?item.commission:0}}
                                 </div>
                            </div>
                                
 
                        </div>

                   

          </div>


                    </van-tab>
                    <van-tab title="Level 3">
                                        <div class="preview_detail">
                   
                        <div class="grid">
                            <div class="grid_wrap gridtitle">
                                 <div class="gridcell">
                                    User
                                 </div>
                                 <div class="gridcell">
                                     Recharge
                                 </div>
                                <div class="gridcell">
                                   Superior
                                </div>
                                <div class="gridcell">
                                    Rebate
                                 </div>
                                 <div class="gridcell">
                                    Commission
                                 </div>
                                  
                            </div> 
                             <div class="grid_wrap " v-for="item in LevelDatas[3]" :key="item.id">
                                 <div class="gridcell">
                                   {{item.username}}
                                 </div>
                                <div class="gridcell">
                                     {{item.recharge?item.recharge:0}}
                                </div>
                                <div class="gridcell">
                                     {{item.susername}}
                                 </div>
                                   <div class="gridcell">
                                    {{item.totalRebat?item.totalRebat:0}}
                                 </div>
                                   <div class="gridcell">
                                      {{item.commission?item.commission:0}}
                                 </div>
                            </div>
                                
 
                        </div>

                   

          </div>
  
                    </van-tab> 
                    </van-tabs>

          </div>

 

            </div>
            <van-popup v-model="timeShow" position="bottom" :style="{ height: '30%' }" >
                <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="Please select a date"
                :min-date="minDate"
                :max-date="maxDate"
                 @confirm="sureDate"
                 @cancel="closePicker"
                />
            </van-popup>

    </div>
</template>
<style scoped>
.content{
    flex-direction: column;
}
</style>
<style>
.preview_detail .van-tabs__nav{
    background: none;
}
</style>
<style scoped>
.time>div:nth-child(2){
    flex-grow:0;
     background: none;
}
.grid{
    margin:12px 12px;
    border-radius:10px;
    background:#ffffff;
}
.grid_wrap{
    display:flex;
    flex-direction:row;
    border-bottom:1px solid #F5F5F5;
}
.gridtitle .gridcell{
    height:44px;
    color:#999999;
    font-size:12px;
    flex-grow:1;
}
.preview_detail .gridcell{
    flex-basis:20%;
}
.gridcell{
   flex-grow:1;
  flex-shrink:1;
    height:44px;
    text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;

}
.preview{
    margin:12px 14px;
    border-radius: 10px;
    background: #ffffff;
    padding:12px;
    display: flex;
    flex-wrap: wrap;
}
.preview>div{
    flex-basis: 50%;
    height:60px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

}
.preview>div>div{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.preview>div>div:nth-child(1){
    font-size: 12px;
    margin-bottom: 10px;
}
.preview>div>div:nth-child(2){
    font-size: 15px;
    font-weight: bold;
}
.direct{
  width:20px;
  background:none;;
  overflow: visible;
  position: relative;
  z-index:1111;
}
.direct img{
    width:28px;
    height:28px;
}
.time{
    position: relative;
    margin:12px 14px;
    display: flex;
    flex-direction: row;

}
.time>div{
    flex-grow: 1;
    background: #ffffff;
    border-radius: 10px;
    height:66px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.time>div>div{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.time>div>div:nth-child(2){
     
   align-items: flex-start;
   font-size:16px;
   color:#333333;
   font-weight: bold;
}
.time>div:nth-child(1){
   
}
.person_header .title{
    font-weight: bold;
    color:#333333;
   
}
.person_header{
    height:50px;
     background: #ffffff;
}
.team_report{
    height:100%;
    background: #F4F5F7;
    font-size:14px;
    
}
.gridtitle .gridcell{
   
}
</style>
 
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
 import {dateFormat} from '@/util/dateUtil'
 
export default {
    data(){
        return {
            infoLevel2List:[],
            infoLevel3List:[],
          active:0,
          levelactive:0,
          startDate:'',
          endDate:'',
          mstartDate:'',
          mendDate:'',
          userInfo:{},
          reportInfo:{},
          infoList:[],
          LevelDatas:{
              1:[],
              2:[],
              3:[]
          },
          timeShow:false,
          modelName:null,
           minDate: new Date(2020, 0, 1),
           maxDate: new Date(2025, 10, 1),
          currentDate: new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate()),
        }
    },
    methods:{
        search:function(type){
          this.infoList=[];
          this.getTeamReport();
        },
        sureDate:function(value){
          var date=dateFormat("YYYY-mm-dd",value);
          this[this.modelName]=date;
          this.timeShow=false;
        },
        closePicker:function(){
            this.timeShow=false;
        },
        changeStartTime:function(name){
            this.timeShow=true;
            this.modelName=name;
        },
        changeEndTime:function(name){
            this.modelName=name;
 this.timeShow=true;
        },
        onClickLeft:function(){
           this.$router.back(-1) ;
        },
     getTeamReport:function(){
          this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
          post("api/user/teamReport",{startdate:this.startDate,enddate:this.endDate}).then(res=>{
              this.reportInfo=res;
              this.$toast.clear();
              for(var i in res.teamData){
                  var level=res.teamData[i].level;
                  this.LevelDatas[level].push(res.teamData[i]); 
              }
          })
     },
     init:async function(){
          this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
          var userInfo=await getUserInfo();
          if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"team"} 
                });
                this.$toast.clear();
                return;
            }
          this.userInfo=userInfo;
          console.log("person 获取用户信息",this.userInfo);
          this.$toast.clear();
          this.getTeamReport();
     }
    },
    activated(){
        this.LevelDatas={
              1:[],
              2:[],
              3:[]
          }
        console.log("tem actived");
         this.startDate=dateFormat("YYYY-mm-dd",new Date());
         this.endDate=dateFormat("YYYY-mm-dd",new Date());
             this.mstartDate=dateFormat("YYYY-mm-dd",new Date());
         this.mendDate=dateFormat("YYYY-mm-dd",new Date());
         this.init();
    },
    mounted(){
        // this.startDate=dateFormat("YYYY-mm-dd",new Date());
        //  this.endDate=dateFormat("YYYY-mm-dd",new Date());
        //  this.init();
    }
}
</script>

