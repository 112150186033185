<template>
    <div class="recharge">
          <div class="recharge_header">
              <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Recharge</div>
                </template>
                
            </van-nav-bar>

          </div>
          <div class="recharge_content">
              <div class="recharge_amout">
                  <div class="recharge_item" :class="{active:item.select}" v-for="(item,index) in amountList" @click="hasSelect(index)" :key="item.id">
                     <a>{{item.amount}}KSH</a>
                  </div>
                   

              </div>
             
              <div class="pay">
                  <a @click="createOrder">Recharge now</a>
              </div>
          </div>
       <f-dialog ></f-dialog>
    </div>
</template>
<style scoped>
.pay{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:20px;
}
.pay a{
    width:224px;
    height:50px;
    font-size:20px;
    color:#ffffff;
    background: #F9034F;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.recharge_amout{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:0px 10px;
}
.recharge_item a{
height:66px;
margin:0px 5px 5px 5px;
background: #ffffff;
border-radius: 10px;
display:flex;
align-items: center;
justify-content: center;
color:#333333;
font-size: 14px;
}
.define_money{
    background: #ffffff;
    border-radius: 10px;
    margin:0px 12px;
    display: flex;
    height:30px;
    align-items: stretch; 
    padding:10px;

}
.define_money input{
    border:1px solid transparent;
    flex-grow: 1;
    font-size: 15px;
    
}
.recharge_item{
    flex-basis: 33%;
    margin-bottom: 5px;
    
}
.recharge_content{
  margin-top:10px;
}
.recharge_header .title{
    font-weight: bold;
    color:#333333;
}
.recharge_header{
    height:50px;
}
  .recharge{
      background: #F4F5F7;
      width:100%;
      font-size:12px;
  }
  .recharge_amout .active>a{
      border:1px solid #F9034F;
      color :#F9034F;
  }
</style>
<script>
import fdialog from './dialog' 
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
export default {
   components:{fDialog:fdialog},
    data(){
        return{
            money:'',
            amountList:[],
            preSelectIndex:null,
            rechargeInfo:null,
            src:null
        }
    },
    methods:{
        onClickLeft:function(){
            this.$router.back(-1)
        },
        getRechargeInfo:function(){
           post("/api/recharge/getRechargeType",{}).then(res=>{
              if(res.code==1){
                  this.rechargeInfo=res.data;
              }
           })
        },
        hasSelect:function(index){
            if(this.preSelectIndex!=null){
                 this.amountList[this.preSelectIndex].select=false;
            }
           this.amountList[index].select=true;
           this.preSelectIndex=index;
        },
        getRechargeAmounts:function(){
              post("/api/recharge/getRechargeAmounts",{}).then(res=>{
                  if(res.code==1){
                      var temp=[];
                      if(res.data&&res.data.length>0){
                          for(var i=0;i<res.data.length;i++){
                            this.amountList.push(Object.assign({},res.data[i],{select:false}))
                          }
                      
                      } 
                  }
                    this.$toast.clear();
              }).catch(err=>{
                    this.$toast.clear();
              })
        },
        createOrder:function(){
            if(this.preSelectIndex==null&&!this.money){
                this.$toast("Please enter the amount to recharge");
                return;
            }
            if(!this.rechargeInfo){
                this.$toast("Payment channel not obtained");
                return;
            }
            if(this.preSelectIndex!=null){
                this.money=this.amountList[this.preSelectIndex].amount;
            }
            var temp={recharge_id:this.rechargeInfo.id,money:this.money,name:''};
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            post("/api/recharge/newRechargeOrder",temp).then(res=>{
                 this.$toast.clear();
                if(res.code_dec=="success"){
                    this.$toast(res.code_dec);
                    console.log(this.src);
                    var self=this;
                    setTimeout(()=>{
                        console.log("延迟执行");
                      if(self.src){
                            
                            self.$router.push({
                                    path: "/"+self.src,
                                });
                        }
                    },1000)
                    
                }else{
                    this.$toast(res.code_dec) 
                }
                
            }).catch(err=>{
                 this.$toast.clear();
            })
        },
       init:async function(){
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            var userInfo=await getUserInfo();
            if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"recharge"} 
                });
                this.$toast.clear();
                return;
            }
            this.getRechargeInfo();
            this.getRechargeAmounts();
          

       }
    },
    activated(){
        this.amountList=[];
     this.init();
      this.$store.dispatch("setShowNav",false);
     this.src=this.$route.query.src;
     console.log("this.src"+this.src);
    },
    created(){
       
    },
    mounted(){

    }
}
</script>

