<template>
    <div class="record">
           <div class="record_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Record</div>
                </template>
                
            </van-nav-bar>
        </div>
        <div class="recordItemwrap">
            <van-list
  v-model="loading"
  :finished="finished"
  :immediate-check="false"
  finished-text="No More"
  @load="onLoad"
>
              <div class="recordItem" v-for="(item,index) in userProducts" :key="index">
                      <div class="product_name">
                          <div>{{item.yuebaoid_name}}</div>
                          <div>{{statusMap[item.status]}}</div>
                      </div>
                      <div class="product_info">
                          <div class="info">
                               <div>
                                    <div>term</div>
<div>{{dateFormat(item.start_time.replace(/\-/g, "/"))}}-{{dateFormat(item.end_time.replace(/\-/g, "/"))}}</div>
                               </div>
                               <div>
                                   <div>
                                       Investment
                                   </div>
                                   <div>{{item.money}}KSH</div>
                               </div>

                          </div>
                          <div class="income">
                               <div class="m">{{item.income_amount}}</div>
                                <div class="d">Profit(KSH)</div>
                          </div>
                      </div>
              </div>
            </van-list>
              <div class="emptyTip" v-if="!userProducts||userProducts.length==0" style="display:flex;flex-direction:column;align-items: center;justify-content: center;color:#999999;font-size:0.35rem;margin-top:0.4rem;">
                   <img src="@/assets/img/ic_no_record.png" style="width:1rem"/>
                   No Records
              </div>

        </div>
    </div>
</template>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
 import {dateFormat} from '@/util/dateUtil'
export default {
   data(){
       return {
            userInfo:null,
            userProducts:[],
            loading:false,
            page:0,
            finished:false,
            statusMap:{1:'Continued'}
       }
   },
   methods:{
       onLoad:function(){
          this.getRecord();
       },
       dateFormat:function(str){
           return dateFormat("YYYY.mm.dd",new Date(str))
       },
       onClickLeft:function(){
            this.$router.back(-1)  
       },
       getRecord:function(){
           this.page++;
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
          post("/api/yuebaojilu/getUserYuebaojiluList",{page_no:this.page}).then(res=>{
              this.$toast.clear();
              console.log("11111111");
              if(res.code==1){
                  if(res.info&&res.info.length>0){ 
                       for(var i=0;i<res.info.length;i++){
                           res.info[i].income_amount=parseInt(res.info[i].money)+parseFloat((res.info[i].money*res.info[i].lilv*res.info[i].daynum).toFixed(2));
                            this.userProducts.push(res.info[i]);
                       }
                  }else{
                      this.finished=true;
                  }
                 
              }else{
                  this.$toast("load failed")
              }

          }).catch(err=>{
              this.$toast("load failed")
          })
       },
       init:async function(){
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
             var userInfo=await getUserInfo(1);
          if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"record"} 
                });
                this.$toast.clear();
                return;
            }
          this.userInfo=userInfo;
          console.log("person 获取用户信息",this.userInfo);
          this.$toast.clear();
          this.getRecord();
       }
   },
       activated(){
           this.userProducts=[];
           this.page=0;
             this.$store.dispatch("setShowNav",false);
             this.init();
    },    
}
</script>
<style scoped>
.income .d{
    color:#999999;
    font-size: 0.3rem;
}
.income .m{
    color:#F9034F;
    font-size:0.45rem;
    font-weight:bold;
}
.income>div{
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    display: flex;
}
.info>div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    font-size: 0.35rem;
    width:100%;
    align-items: center;
}
.info{
    flex-grow: 1;
}
.income{
    width:3.05rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.product_info>div:nth-child(1){
    display: flex;
    flex-direction: column;
}
.product_info{
    display:flex;
    flex-direction: row;
}
.product_name>div:nth-child(1){
    font-size:0.4rem;
}
.product_name>div:nth-child(2){
    font-size:0.4rem;
}
.product_name{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom:0.2rem;
    border-bottom: 0.01rem solid #E5E5E5;
    margin-bottom: 0.1rem;

}
.info>div:nth-child(1){
      border-bottom: 0.01rem solid #E5E5E5;
}
.recordItem{
    display:flex;
    flex-direction: column;
    margin:0.2rem 0.4rem;
    border-radius: 0.3rem;
    background:#ffffff;
    padding:0.3rem;
}
   .record{
       background: #F4F5F7;
        width:100%;
        font-size: 0.4rem;
        padding:0px;
   }
   .record .title{
    font-weight: bold;
    color:#333333;
}
.record_header{
    height:50px;
}
 
 
</style>

